.textOverflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.affordability__table {
  table-layout: fixed;
  font-size: $base-font-size;
  @include devicepoint(tablet) {
    font-size: $font-size-xs;
  }
  .plusIcon {
    &:hover .md-icon span {
      display: block;
    }
  }
  .affordability-table-header {
    .width0 {
      width: 0;
    }

    .width35 {
      width: 38%;
    }

    .width15 {
      width: 12%;
    }
  }

  .share-column {
    margin-right: 20px;
  }

  tr {
    border: 0 !important;
    &.border-remove td {
      border-bottom: 0 !important;
    }
    &.table-row-error td {
      padding-bottom: 40px;
      vertical-align: top;
      &:not(:first-child) {
        padding-top: 6px !important;
      }
    }
  }
  td {
    border-bottom: 1px solid $border-color;
    &.table-btn .md-btn--icon {
      height: 30px;
      width: 30px;
      padding: 3px;
      right: -5px;
    }
    &.uncategorised-row-error .md-icon-text,
    &.uncategorised-row-error .md-select-field .md-icon::before {
      color: $red;
    }
  }
  th {
    border-bottom: 1px solid $primary-color;
  }
  td,
  th {
    padding: 6px 17px;
    &.row-optional-element {
      padding-left: 30px !important;
      .md-text-field {
        height: 35px !important;
        border: 1px solid $border-color;
      }
    }
    &:not(:first-child):not(:last-child) {
      padding: 2px 15px !important;
      @include devicepoint(1400px) {
        padding: 2px 5px !important;
      }
    }
    &:first-child {
      padding-right: 15px !important;
      width: 30%;
      .select_field {
        .md-icon-text {
          flex: none;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
        }
      }
      @include devicepoint(1400px) {
        padding-right: 5px !important;
      }
    }
    &:last-child {
      padding-left: 15px !important;
      @include devicepoint(1400px) {
        padding-left: 5px !important;
      }
    }
    .text-fields___price,
    .text-fields___percentage {
      min-width: 80px;
      max-width: 100px;
    }
    input::-webkit-inner-spin-button {
      display: none;
    }
    input::-moz-inner-spin-button {
      display: none;
    }
    input::-ms-inner-spin-button {
      display: none;
    }
    .text-fields___price:before,
    .text-fields___percentage:before {
      bottom: 2px;
      font-size: $base-font-size;
    }
    .radio-group-btn {
      vertical-align: middle;
    }
    .md-select-field,
    .md-text-field-container {
      .md-text-field {
        height: 22px;
        padding: 0;
        margin: 0;
      }
      hr {
        display: none;
      }
      .md-text--error {
        top: calc(100% + 2px);
      }
    }
    .md-select-field .md-icon::before {
      color: $green;
    }
    .md-select-field.md-select-default {
      .md-icon {
        top: -4px;
        color: $gray500;
        font-size: 0;
        &:before {
          content: "arrow_drop_down";
          font-family: "Material Icons";
          font-size: 30px;
        }
      }
      .md-icon-text {
        height: auto;
        overflow: hidden;
        display: block;
        max-width: 370px;
        padding: 3px 25px 0 0;

        @media only screen and (max-width: 1450px) {
          max-width: 300px;
        }

        @media only screen and (max-width: 1050px) {
          max-width: 250px;
        }

        @media only screen and (max-width: 850px) {
          max-width: 200px;
        }
      }
    }
    .md-select-field:not(.md-select-default) {
      .md-text-field {
        padding-left: 0 !important;
        .md-icon-text {
          height: 20px;
          overflow: hidden;
          display: block;
          padding: 3px 0 0 30px;
        }
      }
      .md-icon {
        right: auto !important;
        left: -4px !important;
        font-size: 0;
        color: transparent;
        top: -4px !important;
        &:before {
          content: "keyboard_arrow_down";
          font-family: "Material Icons";
          font-size: 30px;
        }
      }
    }
    .md-icon-separator {
      line-height: inherit;
      display: inline-block;
      position: relative;
      overflow: hidden;
      margin: 0;
      height: 30px;
      padding: 6px 7px;
      .md-icon {
        position: absolute;
        right: 0;
        top: 5px;
      }
    }
    .md-selection-control-container {
      margin-left: -2px;
      height: auto;
      &--inline {
        margin-right: 15px;
      }
      .md-btn--icon {
        padding: 5px;
        width: 30px;
        height: 30px;
      }
    }
  }
  @include devicepoint(1200px) {
    .radio-group-btn label {
      padding: 0 5px;
    }
    .minWidth-270 {
      min-width: 200px;
      @include devicepoint(1200px) {
        min-width: 0%;
      }
    }
  }
}

.conditions__table {
  @extend .affordability__table;
  td {
    border: none;
    &:first-child {
      width: 100% !important;
      padding-right: 0px !important;
    }
  }
}
.upload-history-table {
  max-width: 100%;
  width: 100%;
  padding-bottom: 5px;
  min-height: 296px;
  max-height: 296px;
  .delete-icon > .md-tooltip-container {
    left: -10px;
  }
}

.upload-moodal-th {
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 1;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 1px;
    bottom: -1px;
    background-color: #000;
  }
}

tr.md-table-row.failed-upload {
  color: $amber;
}

.amm--table {
  tr {
    border: 0;
  }
  .table-header-row td {
    border-bottom: 1px solid $primary-color;
  }
  td {
    border-bottom: 1px solid $border-color;
    &:last-child {
      button:last-child {
        .md-tooltip-container .md-tooltip {
          left: 4px;
        }
      }
    }
    &.disabled {
      cursor: no-drop;
      pointer-events: none;
    }
    &.name {
      max-width: 100px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  th {
    border-bottom: 1px solid $primary-color;
    padding-top: 0;
  }
  td,
  th {
    padding: 9px 10px 8px 10px;
    .md-edit-dialog__label {
      padding: 0;
      vertical-align: bottom;
    }
  }
  .md-edit-dialog__label {
    width: auto;
    white-space: nowrap;
    max-width: 200px;
  }
  .table-btn-group {
    white-space: nowrap;
  }
}

.submit-scenario-list {
  list-style: none;
}
.affordability__table,
.amm--table {
  tr {
    background: transparent !important;
  }
  th,
  td {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    height: auto;

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    &.width-130 {
      width: 130px;
    }
    &.width-150 {
      width: 150px;
    }
  }
  tbody {
    td {
      border-top: 0 !important;
    }
    tr {
      border-bottom: 0 !important;
    }
  }
  hr {
    margin: 10px 0 -11px;
    vertical-align: bottom;
    background: $gray300;
  }
}

.table-btn {
  padding: 0 !important;
  text-align: right;
  white-space: nowrap;
  > span {
    padding: 10px 0;
    display: inline-block;
  }
  @include devicepoint(1200px) {
    .md-btn--text {
      font-size: $font-size-xs - 1px;
    }
  }
}

.docusign-logo {
  float: right;
  margin-top: 25px;
  width: 223px;
  height: 24px;
  background-image: url("../images/submit-docusign-tagline.png");
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 991px) {
    width: 109px;
    background-image: url("../images/submit-docusign.png");
  }
}

.table-pad-0 {
  padding: 0 !important;
  width: 160px; /* narrower column */
  white-space: nowrap;
}

.submit-scenario-table-column2 {
  text-align: right;
}
.submit-scenario--table-loader {
  &.loader-show:after {
    right: 10px;
  }
}
.submit-scenario--table {
  th {
    width: 100% !important;
  }
}

.upload-table-btn {
  > span {
    padding: 10px 0;
    display: inline-block;
  }
  @include devicepoint(1200px) {
    .md-btn--text {
      font-size: $font-size-xs - 1px;
    }
  }
}

.failed-upload-row-buttons {
  display: none !important;
}

.user-table {
  button {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .md-btn--icon {
    color: $gray100;
  }
  &.loader-show:after {
    left: 0;
    right: 0;
    margin: auto;
    top: 50px;
  }
  th {
    text-transform: uppercase;
    &:last-child {
      border-right: 0;
      text-align: right;
    }
    .md-icon-text:first-child {
      padding-right: 2px;
    }
  }
  .history-modal-header {
    th {
      &:last-child {
        text-align: left !important;
      }
    }
  }
  td {
    &.users-table-lastname {
      max-width: 123px;
      @extend .textOverflow;
    }

    &.users-table-firstname {
      max-width: 123px;
      @extend .textOverflow;
    }

    &.users-table-email {
      max-width: 250px;
      @extend .textOverflow;
    }

    &.users-table-role {
      max-width: 150px;
      @extend .textOverflow;
    }

    &.upload-column {
      @include devicepoint("991px") {
        &.hide-sm {
          display: none;
        }
      }
      .upload-icon {
        width: 18px;
        min-width: 18px;
        margin-right: 5px;
        fill: currentColor;
        vertical-align: baseline;
        margin-bottom: -3px;
        margin-top: -2px;
      }
    }
    .md-dialog {
      display: none;
    }
  }
  .table-btn {
    width: auto;
    .md-btn .md-icon {
      font-size: 19px;
      overflow: hidden;
      max-width: 100%;
    }
  }
  .md-table-body > tr:hover {
    td:not(.upload-status) {
      color: $green;
    }

    td:not(.upload-status),
    .copy-icon-inner,
    .md-icon {
      transition: all 0.3s !important;
    }
  }
  .md-table-body > tr > td {
    .delete-icon:hover {
      color: $red;
    }
    .copy-icon-inner:hover {
      stroke: $green;
    }
    .load-btn:hover,
    .default-icon-btn:hover,
    .duplicate-btn:hover {
      color: $green;
    }
    .assign-icon:hover {
      fill: $green;
    }
  }
  .scenario-status {
    min-width: 150px;
    // padding-right: 0 !important;
    @media only screen and (min-width: 1101px) and (max-width: 1300px) {
      min-width: 100px;
    }
    .md-edit-dialog__label {
      display: inline-block;
    }
  }
  .usertable-btn-column {
    white-space: nowrap;
    background: #efefef;
    td {
      display: inline-block;
      padding: 0;
      border: 0 !important;
      > div {
        display: inline-block;
        color: $green !important;
      }
    }
    .md-btn {
      padding: 0;
      min-width: 0 !important;
      margin-right: 20px;
      border: 0 !important;
      width: auto;
      color: $black !important;
      &:disabled {
        opacity: 0.8;
      }
      &:not([disabled]):hover {
        color: $green !important;
      }
      .md-icon,
      svg {
        vertical-align: middle;
        margin-right: 10px;
        min-width: 20px;
        width: 20px;
        color: currentColor;
        height: 20px;
        font-size: 22px;
        line-height: 18px;
        transition: inherit !important;
        .copy-icon-inner {
          stroke: currentColor;
        }
      }
      &.text-danger {
        color: $red !important;
      }
    }
  }
}
.upload-table {
  button {
    width: 30px;
    height: 30px;
    padding: 5px;
  }
  .md-btn--icon {
    color: $gray100;
  }
  &.loader-show:after {
    left: 0;
    right: 0;
    margin: auto;
    top: 50px;
  }
  th {
    text-transform: uppercase;
    &:first-child {
      min-width: 80px;
      width: 80px;
    }
    &:last-child {
      border-right: 0;
      min-width: 70px;
      width: 70px;
    }
    &:nth-child(5) {
      min-width: 110px;
      width: 110px;
    }
    &:nth-child(4) {
      min-width: 15%;
      width: 15%;
    }
    .md-icon-text:first-child {
      padding-right: 2px;
    }
  }
  td {
    &.upload-column {
      @include devicepoint("991px") {
        &.hide-sm {
          display: none;
        }
      }
      .upload-icon {
        width: 18px;
        min-width: 18px;
        margin-right: 5px;
        fill: currentColor;
        vertical-align: baseline;
        margin-bottom: -3px;
        margin-top: -2px;
      }
    }
    .md-dialog {
      display: none;
    }
  }
  .table-btn {
    width: auto;
    .md-btn .md-icon {
      font-size: 19px;
      overflow: hidden;
      max-width: 100%;
    }
  }
  .md-table-body > tr:hover {
    td:not(.upload-status),
    .copy-icon-inner,
    .md-icon {
      transition: all 0.3s !important;
    }
  }
  .md-table-body > tr > td {
    .delete-icon:hover {
      color: $red;
    }
    .copy-icon-inner:hover {
      stroke: $green;
    }
    .load-btn:hover,
    .default-icon-btn:hover,
    .duplicate-btn:hover {
      color: $green;
    }
    .assign-icon:hover {
      fill: $green;
    }
  }
  .scenario-status {
    min-width: 150px;
    @media only screen and (min-width: 1101px) and (max-width: 1300px) {
      min-width: 100px;
    }
    .md-edit-dialog__label {
      display: inline-block;
    }
  }
  .usertable-btn-column {
    white-space: nowrap;
    background: $gray50;
    td {
      display: inline-block;
      padding: 0;
      border: 0 !important;
      > div {
        display: inline-block;
        color: $green !important;
      }
    }
    .md-btn {
      padding: 0;
      min-width: 0 !important;
      margin-right: 20px;
      border: 0 !important;
      width: auto;
      color: $black !important;
      &:disabled {
        opacity: 0.8;
      }
      &:not([disabled]):hover {
        color: $green !important;
      }
      .md-icon,
      svg {
        vertical-align: middle;
        margin-right: 10px;
        min-width: 20px;
        width: 20px;
        color: currentColor;
        height: 20px;
        font-size: 22px;
        line-height: 18px;
        transition: inherit !important;
        .copy-icon-inner {
          stroke: currentColor;
        }
      }
      &.text-danger {
        color: $red !important;
      }
    }
  }
}
.duplicate-icon {
  stroke-width: 6px;
  stroke: currentColor;
}

.assign-icon {
  fill: gray;
}
.dashboard-table {
  td.upload-column {
    white-space: nowrap;
  }
  th {
    height: 35px;

    &.dashboard-table-id {
      width: 45px;
    }

    &.dashboard-table-loanid {
      width: 95px;
    }

    &.dashboard-table-loanamount {
      width: 92px;
    }

    &.dashboard-table-modifieddate {
      width: 108px;
    }

    &.dashboard-table-actions {
      width: 160x;
    }
  }

  td {
    &.dashbord-table-lastname {
      @extend .textOverflow;
    }

    &.dashboard-table-status {
      @extend .textOverflow;
      max-width: 155px;
    }

    &.dashboard-table-capitalstatus {
      @extend .textOverflow;
      max-width: 154px;
    }
  }

  @media only screen and (max-width: 1200px) {
    td,
    th {
      &.scenario-status {
        min-width: 100px;
      }
    }
  }
  @media only screen and (min-width: 992px) {
    tbody tr:hover td {
      background: #f5f5f5;
    }
    th {
      border-right: 0;
      position: relative;
      &:not(.border-rt-0):after {
        content: "";
        position: absolute;
        right: 0;
        width: 1px;
        height: 100%;
        background: #efefef;
        top: 0;
      }
      .material-icons.md-icon {
        font-size: 18px;
      }
      &.border-rt-0 {
        border-right: 0;
      }
    }
  }
  @include devicepoint(tablet) {
    th {
      .md-icon-text {
        flex-grow: 0;
        &:after {
          content: ":";
        }
      }
    }
    td,
    th {
      padding: 9px 16px 8px !important;
    }
    td.expand-more-column {
      padding-right: 10px !important;
    }

    .expand-less {
      @include devicepoint("991px") {
        opacity: 0.4;
      }
    }

    .expand-more {
      width: 27px !important;
      height: 27px !important;
      padding: 0;
      transition: transform 0.3s;
      &.expand-less {
        transform: rotate(180deg);
      }
      .md-icon {
        font-size: 25px !important;
        color: $black;
        line-height: 27px;
        display: block;
      }
    }
  }

  td {
    .history-btn {
      .md-icon {
        width: 20px;
      }
    }
  }
  @include devicepoint(tablet) {
    // tr:not(.usertable-row) td {
    //     border-bottom: 0 !important;
    // }
    tr.usertable-row.hide {
      display: none;
    }
  }
}

.md-text-right .md-text-field-container {
  float: right;
  input {
    text-align: right;
  }
}

.md-table-pagination--controls {
  margin-left: auto !important;
  margin-right: 0;
  right: 0;
}

.default-btn-group {
  .md-btn {
    margin-left: 10px;
  }
}

.md-table-footer--pagination {
  td {
    position: relative;
    text-align: right;
    border: 0 !important;
    .md-table-pagination {
      height: 36px;
    }
    .md-table-pagination__label,
    .md-menu--select-field {
      display: none;
    }
    .md-btn:not(disabled):hover {
      color: $green !important;
    }
  }
}

.dashboard-table {
  overflow-y: hidden;
  padding-bottom: 40px;

  .scenario-table-pagination {
    .md-table-pagination__label,
    .md-menu--select-field {
      display: inline;
    }
    span.md-table-pagination__label {
      margin-right: 10px;
    }
  }
}

.upload-icon {
  max-width: 18px;
  fill: currentColor;
}

.dependants-table th:first-child {
  width: 50% !important;
  > small {
    margin-left: 5px;
    opacity: 0.5;
  }
}

.dependants-table td input {
  padding-left: 0 !important;
}

.scenario-msg {
  margin-top: -74px;
  padding-right: 315px;
  line-height: 1.2;
}

.padding-right-5 {
  padding-right: 5px !important;
}

// mortage choice css
.mc-dashboard-table.user-table .scenario-status .upload-table {
  min-width: 90px;
}
.mc-dashboard-table {
  @media only screen and (max-width: 991px) {
    td,
    th {
      padding: 9px 5px 8px 5px !important;
    }
  }
}

.notification-table {
  font-size: 15px;
  th:first-child,
  tr:first-child {
    width: 82%;
  }
}

.employment--table {
  hr {
    margin-top: 6px !important;
  }
}

.employment_selectfield {
  hr {
    margin-top: 0px !important;
  }
}

.MuiDialog-container {
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 8px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
