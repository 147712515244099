.text-theme-color {
    color: var(--primary-color);
}

.export-csv.loader-show::after {
    right: 132px !important;
    bottom: 8px !important;
}

.report-table-layout {
    display: grid;
    grid-template-columns: auto auto;
    border-bottom: 0;
    > span {
        padding: 6px 20px 4px 0;
        > img {
            margin-right: 15px;
        }
        &:nth-child(2n) {
            text-align: right;
            padding-right: 0;
        }
    }
}

.report-back-button {
    position: absolute;
    top: 95%;
}
