.app-logo {
  max-width: 100px;
}

.login-wrap {
  text-align: center;
  padding: 50px 0;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 100vh;
  background: url("../images/login-bg.png");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  h2 {
    margin-top: 30px;
    margin-bottom: 50px;
    text-transform: uppercase;
    font-size: 20px;
    letter-spacing: 1px;
  }
  h5 {
    margin-bottom: 30px;
  }
  h6 {
    margin-bottom: 30px;
    a {
      color: inherit;
    }
  }
  a {
    font-size: $font-size-sm;
  }
  .group-row {
    margin-bottom: 30px;
  }
  & + .side_widget {
    display: none;
  }
}

.login-form {
  max-width: 500px;
  margin: 30px auto;
  border: 1px solid #eee;
  padding: 40px;
  border-radius: 4px;
  background: $white;
  label {
    text-align: left;
    display: block;
  }
  > .app-logo {
    background-image: url("/logos/amm-logo-dark.svg");
    width: 215px;
    height: 84px;
    max-width: 100%;
  }
  .md-text-field-container .md-text-field-message-container {
    text-align: left;
  }
  .md-text-field {
    height: 28px;
  }
  .md-password-btn {
    padding-top: 7px;
  }
  .md-password-btn::after {
    margin-top: 7px;
  }
}

// history modal
.showHistoryModal-edit-dialog .md-edit-dialog.md-dialog,
.showHistoryModal-edit-dialog .md-edit-dialog__content,
.navigateToUpload-edit-dialog .md-edit-dialog__content {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

#noteModal,
#historyAndUpload,
#selectBrokerModal ul.modal-list li label {
  width: auto !important;
}

.password-success.md-text-field-container {
  hr.md-divider,
  hr.md-divider:after {
    background-color: green !important;
  }
}
.password-medium.md-text-field-container {
  hr.md-divider,
  hr.md-divider:after {
    background-color: orange !important;
  }
}
.password-error.md-text-field-container {
  hr.md-divider,
  hr.md-divider:after {
    background-color: $red !important;
  }
}
