.tab-header-group {
  position: relative;
  .plusIcon {
    position: absolute;
    right: 0;
    top: 8px;
    z-index: 1;
    white-space: nowrap;
  }
}
.tabHeader {
  list-style-type: none;
  margin: 0 0 70px 0;
  padding: 0px 130px 0 0;
  border-bottom: 1px solid $black;
  display: flex;
  flex-wrap: wrap;

  li {
    position: relative;
    box-sizing: border-box;
    display: inline-block;
    margin-right: 30px;
    padding: 0 30px 0 0;
    text-transform: uppercase;
    margin-bottom: -3px;
    max-width: 50%;
    cursor: pointer;
    .tab-name {
      padding: 5px 0px;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    animation-name: fadeIn;
    animation-duration: 0.15s;
    animation-timing-function: linear;
    animation-delay: 0;
    animation-iteration-count: 1;
    border-bottom: 3px solid transparent;
    &:hover {
      border-bottom: 3px solid $black;
    }
    &.active {
      border-bottom: 3px solid $black;
      cursor: default;
    }
    &.invalid {
      color: $red;
    }
  }
}

.closeButton {
  position: absolute;
  top: 2px;
  right: -8px;
  width: 30px;
  height: 30px;
  padding: 5px;
  font-size: $font-size-lg + 2px;
  color: $gray100;
  .material-icons.md-icon {
    font-size: inherit;
  }
  &:disabled {
    display: none;
  }
}

.user-detail-proviso-tabs .md-tabs-content {
  min-width: 350px;
}

// react md tabs

.md-tabs {
  background: transparent !important;
  padding: 0 !important;
  display: block;
  border-bottom: 1px solid $primary-color;
  margin-bottom: 70px;
  li {
    color: $primary-color;
    display: inline-block;
    text-align: left;
    height: auto;
    padding: 5px 0;
    border-bottom: 3px solid transparent;
    margin-right: 30px;
    min-width: initial;
    max-width: none;
    margin-bottom: -3px;
    overflow: visible;
    &[aria-selected="true"] {
      border-bottom-color: $primary-color;
    }
    .md-tab-label {
      display: block;
      font-size: inherit;
      line-height: inherit;
      font-weight: inherit;
    }
  }
  .md-tab-indicator {
    display: none;
  }
}
.md-tabs-content .md-tab-panel {
  padding: 0;
}
.user-detail-proviso-tabs,
.linked-services {
  .md-tabs-content {
    overflow: visible !important;
    .react-swipeable-view-container {
      transform: none !important;
      display: block !important;
      flex-direction: unset !important;
      > div {
        height: auto !important;
        overflow: visible !important;
        &[aria-hidden="true"] {
          display: none;
        }
      }
    }
  }
}
