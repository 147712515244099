//search box
.ais-SearchBox-form {
  border: 1px solid $darkgray;
  border-radius: 2px;
  margin-top: 5px;
  color: $lightgray;
  position: relative;
  &:hover {
    background: $white;
    .ais-SearchBox-input {
      background: $white;
      color: $darkgray;
    }
    .ais-SearchBox-submitIcon {
      fill: $md-secondary-color;
    }
  }
}
.ais-SearchBox-input {
  width: 100%;
  background-color: $gray;
  outline: none;
  padding: 9px 30px 9px 15px;
  border: none;
  font-size: 15px;
  -webkit-appearance: none;
  color: $white;
  transition: all 150ms ease;
  &:placeholder-shown {
    color: $darkgray;
  }
  &:focus {
    background: $white;
    color: $darkgray;
    + button .ais-SearchBox-submitIcon {
      fill: $md-secondary-color;
    }
  }
}

.ais-SearchBox-submit {
  background-color: transparent;
  border: none;
  position: absolute;
  right: -1px;
  padding: 11px 10px;
  outline: 0;
}

.ais-SearchBox-submitIcon {
  fill: $darkgray;
  height: 15px;
  width: 15px;
  vertical-align: middle;
}

.ais-SearchBox-reset {
  background-color: initial;
  border: none;
  position: absolute;
  right: 19px;
  padding: 12px 10px;
  outline: 0;
}

.ais-SearchBox-resetIcon {
  display: none;
}

@media screen and (max-width: 1024px) {
  .ais-SearchBox-resetIcon {
    display: inherit;
  }
}

.ais-Hits-list {
  padding-left: 0;
  li {
    list-style-type: none;
    cursor: pointer;
    list-style-position: inside;
    border-bottom: 1px solid $darkgray;
    border-radius: 2px;
    padding-bottom: 1px;
    padding: 10px 0 5px 0;
    &:hover {
      background: #444;
      transition: background 150ms ease;
    }
  }
}
.ais-Hits-item > div > span {
  font-weight: 500;
}
// hit-list
.ais-Highlight-nonHighlighted {
  color: $white;
}

.ais-Highlight-highlighted {
  color: $md-secondary-color;
}
.algolia-records {
  cursor: pointer;
}