.property-select-icon {
  display: inline-block;
  position: relative;
}

.property-select-icon {
  .objective-bed-icon,
  .objective-car-icon,
  .objective-bathroom-icon {
    position: absolute;
    left: 0px;
    bottom: 3px;
    pointer-events: none;
    width: 20px;
    height: 14px;
  }
}

.property-category-4 {
  color: red !important;
}

.rectangleUp-icon,
.rectangleDown-icon,
.thumbsUp-icon,
.objective-key-icon,
.objective-category-icon,
.objective-house-icon,
.objective-high-density-icon,
.objective-property-pdf-icon {
  fill: $green;
  margin-right: 14px;
}
.objective-dollar-icon {
  margin-right: 13px;
}

.gray-bg {
  background: $gray50;
}
.white-bg {
  background: $white;
}
.fullWidth-section {
  padding: 60px 0 70px 0;
  h3 {
    color: $green;
    margin-top: 0;
    margin-bottom: 45px;
  }
}
.main-container .objective-page section.full-width--row {
  margin-top: 74px;
}
.list-table-layout {
  display: grid;
  grid-template-columns: auto 1fr;
  border-bottom: 0;
  > span {
    padding: 6px 20px 4px 0;
    border-bottom: 1px solid $gray400;
    > img {
      margin-right: 15px;
    }
    &:nth-child(2n) {
      text-align: right;
      padding-right: 0;
    }
  }
}
.estimated-table {
  border-top: 1px solid $black;
  span {
    &:nth-child(1) img {
      /* Statistical value */
      margin-right: 19px;
    }
    &:nth-child(3) img {
      /* Confidence level */
      margin-right: 16px;
    }
    &:nth-child(11) img {
      /* Property type */
      margin-right: 10px;
    }
    &:nth-child(13) img {
      /* Property category */
      margin-right: 16px;
    }
  }

  @include devicepoint(tablet) {
    width: 100%;
  }
}

.map-container {
  max-height: calc(100% - 5px);
  min-height: calc(100% - 5px);
  height: 450px;
}

.gallery-map- {
  position: relative;
  .map-gallery-thumbnail {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 120px;
    z-index: 1;
    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border: 1px solid #efefef;
      display: block;
      border-right: 0;
      border-bottom: 0;
      cursor: pointer;
    }
  }
}
@include devicepoint("991px") {
  .map-gallery-modal .md-dialog--centered {
    width: auto;
    max-width: 100%;
    top: 20px;
    left: 30px;
    right: 30px;
    .image-gallery-slide img {
      height: auto;
      max-height: 70vh;
    }
  }
}
.gallery-img img,
.google__map--img img {
  width: 100%;
  border: 1px solid $gray100;
}
.image-gallery-thumbnail {
  border: 0 !important;
  margin: 0 !important;
  img {
    height: 75px;
    object-fit: cover;
  }
}
.image-gallery-content:not(.fullscreen) .image-gallery-slide img {
  height: 300px;
  object-fit: cover;
}
.image-gallery-content.fullscreen {
  .image-gallery-slide img {
    height: 100vh;
    object-fit: cover;
  }
  .image-gallery-thumbnails-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
.suburb-switch-view {
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: -60px;
  .md-btn {
    min-width: 50px !important;
    margin-left: 10px;
  }
}
.radio__yesOption--disable input[type="radio"][value="Yes"] + label {
  pointer-events: none;
  opacity: 0.4;
}

.radio__noOption--disable input[type="radio"][value="No"] + label {
  pointer-events: none;
  opacity: 0.4;
}

.property-accepted-icon,
.property-not-accepted-error {
  position: relative;
  margin-right: 13px;
  top: 8px;
}

.ids-logo-section {
  font-size: 12px;
  color: $gray100;
  margin-top: 35px;
  margin-bottom: -30px;
  display: flex;
  text-align: right;
  padding: 0 5px;
  line-height: 1.2;
  padding-left: calc(100% - 840px);
  img {
    width: 32px;
    height: 25px;
    margin-left: 20px;
  }
}

.avm-download {
  border: none !important;
  color: $green;
  text-align: right;
  text-transform: none !important;
  padding-bottom: 3px;
}

.avm-download-btn {
  color: var(--primary-color);
}
