.applicant-page {
  .identification-section {
    margin-top: 10px;
  }
  @media screen and (min-width: 1025px) {
    .material-icons.md-icon {
      font-size: 24px;
    }
  }
}
.applicant-date-and-firsthome-section {
  .date-picker {
    @extend .group-row;
    @include devicepoint("991px") {
      margin-bottom: 0;
    }
  }
}
.MuiFilledInput-underline:after {
  border-bottom: 2px solid $green !important;
}

.md-text--theme-primary {
  color: $green;
}

.applicant-name,
.applicant-name-and-title,
.current-address-section,
.contact-detail-section {
  grid-column-gap: 30px;
  > label {
    margin-bottom: 10px;
  }
}

.applicant-name-and-title {
  @include grid(100px 1fr);
  // @include devicepoint(1300px) {
  //     @include grid(1fr 1fr);
  // }
  @include devicepoint(tablet) {
    display: block;
  }
}

.current-address-section {
  @extend .grid-6-col;
  @include devicepoint(tablet) {
    display: block;
  }
}

.contact-detail-section {
  @include grid(1fr 0.47fr 0.47fr);
  @include devicepoint(tablet) {
    display: block;
  }
  > label {
    @include grid-column-start(1);
    @include grid-column-end(3);
  }
}

.identification-section {
  h3 {
    text-transform: uppercase;
  }
}

.applicant-documents-list {
  margin-bottom: 15px;
  position: relative;
  @include devicepoint(tablet) {
    border-top: 1px solid;
    padding-top: 10px;
  }
  .plusIcon {
    position: absolute;
    right: 0;
    z-index: 1;
    top: 2px;
    @include devicepoint(1550px) {
      &.mt_-30 {
        top: -30px;
      }
    }
    @include devicepoint(tablet) {
      top: -30px;
    }
  }
  .plusIcon .md-icon span {
    display: block;
    background: transparent;
  }
  .label__animation_remove label:not(.md-text--error) {
    color: inherit;
  }
}
.md-divider--text-field-error::after {
  background: $red !important;
}
.applicant-list-row {
  @extend .grid-6-col;
  @include grid-gap(30px);
  position: relative;
  @include devicepoint(tablet) {
    display: grid;
  }
  .md-select-field:not(.md-select-default) .md-select-field--text-field > span {
    max-width: 90%;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .grid-6-col {
    display: grid;
  }
  .label__animation_remove {
    margin-bottom: 60px;
  }
  .md-btn {
    position: absolute;
    right: -10px;
    display: none;
    color: inherit;
    bottom: 60px;
    @include devicepoint(991px) {
      bottom: 88px;
    }
  }
  .md-btn-license {
    bottom: 78px !important;
  }
  &:hover .md-btn {
    display: block;
  }
}

.auto-complete-address-error {
  > label {
    color: $red;
  }
  .md-autocomplete:after {
    content: "Select the address from dropdown" !important;
    font-size: 12px;
    color: $red;
    /* display: block; */
  }
  hr {
    background: $red;
    &:after {
      background: $red !important;
    }
  }
  .loader-show:after {
    bottom: 27px;
  }
}

.auto-complete-address-amber {
  hr {
    background: $amber;
    &:after {
      background: $amber !important;
    }
  }
  .loader-show:after {
    bottom: 27px;
  }
}

.suburb-alt-text {
  color: $amber;
}
.auto-complete-error {
  > label {
    color: $red;
  }
  .md-autocomplete:after {
    content: "This field is required";
    font-size: 12px;
    color: $red;
    /* display: block; */
  }
  hr {
    background: $red;
    &:after {
      background: $red !important;
    }
  }
  .loader-show:after {
    bottom: 27px;
  }
}

.sms-loader {
  margin-left: 25px;
  top: 10px;
}

// privacy section
.privacy-section {
  p {
    font-size: inherit;
    margin-bottom: 20px;
  }
  .otp-message {
    margin-bottom: 15px;
    &.group-row {
      margin-top: 30px;
      margin-bottom: 0px;
    }
  }
  .otp-section {
    .loader-show {
      &:after {
        right: auto;
        left: -30px;
        top: 0;
      }
    }
  }
  .resend-input-section {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    position: relative;
    margin-bottom: 50px;
    align-self: flex-start;
    max-width: 540px;
    > .label {
      width: 100%;
      margin-bottom: 27px;
    }
    .md-text-field-container {
      width: calc(100% - 30px - 300px);
      margin: 0 15px;
      hr {
        margin-bottom: 0;
      }
    }
    > .md-icon {
      margin-left: 30px;
    }
    .md-btn {
      min-width: 116px !important;
    }
    .block-span {
      position: relative !important;
      margin: 10px 0 0 !important;
      right: 0 !important;
      &:after {
        top: 0;
        right: 0;
        position: relative !important;
        width: 20px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .verify-loader {
    margin-top: 8px;
    padding-left: 12px;
    margin-bottom: 8px;
    margin-left: 25px;
    @media screen and (max-width: 991px) {
      margin-top: 11px;
      margin-bottom: 11px;
    }
    &:after {
      left: -13px;
      right: auto;
      bottom: 0px;
    }
  }

  .override-loader {
    &:after {
      left: -11px;
      bottom: 0px;
    }
  }
}

.response--message-section {
  padding-top: 50px;
  display: none;
  &.response_box-show {
    display: flex;
  }
}

.response-message {
  position: relative;
  margin-right: 50px;
  > .md-icon {
    position: absolute;
    left: 1px;
    top: 2px;
    font-size: 35px !important;
  }
  h6 {
    padding-left: 50px;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 15px;
  }
  &.response-error {
    .md-icon,
    h4 {
      color: $red;
    }
  }
  &.response-success {
    .md-icon,
    h4 {
      color: $green;
    }
  }
  &.credit-check-success {
    h6 {
      margin-top: -40px;
    }
  }
}

.override-credit-check {
  margin: 10px 0px 0px 45px;
}
.no-of-dependents-label {
  font-size: 12px;
  color: #999;
  margin-left: 5px;
}

.no-of-dependents-label-hide {
  display: none;
}

.address-copy-address {
  display: flex;
  align-items: baseline;
}

.copy-address {
  color: $green;
  font-size: 12px;
  margin-left: 5px;
  cursor: pointer;
}

.add-note-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // @include devicepoint(tablet) {
  //   margin-bottom: 60px;
  // }
  padding-top: 35px;

  > span {
    font-size: 12px;
  }

  .error {
    color: $red;
  }
}
.margin-left-10 {
  margin-left: 10px !important;
}

.employment-probation-warning {
  color: $red !important;
}
.employment-probation-warning-text {
  font-size: 12px !important;
}

.privacy-section-buttons {
  display: flex;
  justify-content: flex-start;
  .md-selection-control-container {
    margin-left: 0px;
  }
  .perform-identity-verification-button {
    margin-right: 10px;
  }
  @media screen and (max-width: 991px) {
    margin: 6px;
  }
}
