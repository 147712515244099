.footerProgress {
  background: $green;
  height: 3px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  transition: width 0.3s ease-in-out;
}
.footer-navigation {
  background: rgba(63, 63, 63, 0.95);
  -webkit-backdrop-filter: blur(2px);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 25%;
  z-index: 1;
  padding: 0 50px;
  @media only screen and (min-width: 1101px) and (max-width: 1300px) {
    right: 21.9%;
    padding: 0 20px;
  }
  @media only screen and (min-width: 1500px) {
    padding: 0 60px;
  }
  @include devicepoint(1100px) {
    right: 160px;
    padding: 0 30px;
  }
  @include devicepoint(tablet) {
    right: 130px;
    padding: 0 20px;
  }
}
.formNavigationSteps {
  display: flex;
  list-style: none;
  font-weight: 300;
  color: $white;
  justify-content: space-between;
  > li {
    width: auto;
    &.disable-menu {
      pointer-events: none;
      a {
        color: $gray500;
        .icon,
        .md-icon {
          color: $gray500;
          fill: $gray500;
        }
      }
    }
    svg.icon {
      fill: currentColor !important;
      .chart-icon---1,
      .chart-icon---2 {
        fill: currentColor !important;
      }
    }
    .md-icon,
    .icon {
      color: $green;
      font-size: $h2 - 2px;
      margin-right: 10px;
      height: 34px;
      transition: color 0.15s ease;
      pointer-events: none;
      @include devicepoint(991px) {
        margin-right: 0;
      }
    }
    > a,
    > span {
      display: flex;
      margin: 0 -30px;
      padding: 24px 30px;
      height: 100%;
      color: inherit;
      position: relative;
      align-items: center;
      cursor: pointer;
      @include devicepoint(991px) {
        display: block;
        margin: 0;
        padding: 20px 10px;
        text-align: center;
      }
      &:hover {
        color: $green;
        opacity: 1 !important;
        // .icon,
        // .md-icon {
        //   color: $green500;
        // }
      }
    }

    .primary {
      text-transform: uppercase;
      font-size: 22px;
      line-height: 1em;
      @include devicepoint(1370px) {
        font-size: $h5 + 2px;
      }
      @include devicepoint(991px) {
        font-size: $h6;
      }
      @include devicepoint(1312px) {
        font-size: $h5;
      }
    }
  }
}

.footer-review-icon {
  @include devicepoint("1440px") {
    & + .footer-heading {
      display: none;
      @include devicepoint("991px") {
        display: block;
      }
    }
  }

  .cls-1,
  .cls-2 {
    fill: currentColor !important;
  }
  .cls-2 {
    fill-rule: evenodd;
  }
}
.footer-icon {
  .cls-1,
  .cls-2 {
    fill: currentColor !important;
  }
}

.show-valid-icon {
  background: $green !important;
  color: $white !important;
  font-weight: 700;
  border-radius: 50%;
  transition: background 150ms ease;
}
.show-error-indicator,
.show-valid-icon {
  width: 14px;
  height: 14px !important;
  font-size: 14px !important;
  line-height: 14px;
  display: inline-block;
  margin-left: 10px;
  position: absolute;
  left: 45px;
  margin-top: -7px;
}

.show-error-indicator-upload {
  &:before {
    content: "!";
    width: 39px;
    height: 39px;
    background: $white;
    color: $amber;
    display: inline-block;
    text-align: center;
    font-weight: 400;
    border-radius: 50%;
    line-height: 39px;
    font-size: 39px;
    position: relative;
    border: 1px solid $amber;
    margin-right: 10px;
    transition: background 150ms ease !important;
  }
}

.show-error-indicator {
  &:before {
    content: "!";
    width: 14px;
    height: 14px;
    background: $red;
    color: $white;
    display: inline-block;
    text-align: center;
    font-weight: 600;
    border-radius: 50%;
    line-height: 14px;
    font-size: 14px;
    position: relative;
    top: -3px;
    transition: background 150ms ease !important;
  }
}

.disable-menu .show-valid-icon,
.disable-menu .show-error-indicator:before {
  background: $gray500 !important;
}
.hide-error-indicator {
  display: none !important;
}
@media only screen and (max-width: 991px) {
  .show-error-indicator,
  .show-valid-icon {
    margin-left: 0;
    position: absolute;
    top: 27px !important;
    left: calc(50% + 9px);
  }

  .show-error-indicator:before {
    top: 0px;
  }
}

li.footer-devider {
  position: relative;
  &:after {
    content: "";
    height: 50%;
    width: 1px;
    background: #555555;
    display: block;
    margin: auto;
    top: 0;
    position: absolute;
    bottom: 0;
  }
}

.show-warning {
  &:before {
    content: "!";
    width: 39px;
    height: 39px;
    background: #EFEFEF;
    color: $amber;
    display: inline-block;
    text-align: center;
    font-weight: 400;
    border-radius: 50%;
    line-height: 39px;
    font-size: 39px;
    position: relative;
    border: 2px solid $amber;
    margin-right: 10px;
    transition: background 150ms ease !important;
  }
}

.warning-content{
  margin-top: 8px !important;
}
