.main-container {
  width: calc(100% - 25%);
  padding: 0 50px 75px;

  .full-width--row {
    margin-left: -50px;
    margin-right: -50px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media only screen and (min-width: 1500px) {
    padding: 0 60px 75px;
    .full-width--row {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @include devicepoint(1250px) {
    padding-bottom: 82px !important;
  }
  @include devicepoint(991px) {
    padding-bottom: 100px !important;
  }
  @media only screen and (min-width: 1101px) and (max-width: 1300px) {
    padding: 0 20px 75px;
    width: calc(100% - 22%);
    .full-width--row {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  @include devicepoint(1100px) {
    padding: 0 30px 75px;
    width: calc(100% - 160px);
    .full-width--row {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @include devicepoint(tablet) {
    padding: 0 20px 75px;
    width: calc(100% - 130px);
    .full-width--row {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &.sidebar-show {
    height: 100vh;
    overflow: hidden;
  }
  .promo-banner {
    height: 35px;
    background-color: $primary-color;
    color: $white;
    text-align: left;
    line-height: 34px;
    a:link,
    a:visited {
      color: $white;
      text-decoration: underline;
    }
    @media (max-width: 1100px) {
      font-size: 15px;
      margin: 0 -60px;
      padding: 10px 60px;
      line-height: 15px;
      height: 50px;
    }
  }

  .page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  input::-webkit-credentials-auto-fill-button {
    visibility: hidden;
  }
}

.margin-bottom-20 {
  margin-bottom: 20px;
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .MuiFormLabel-root.Mui-focused {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
}

.span-color {
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
  .MuiFormLabel-root.Mui-focused {
    border-color: var(--primary-color) !important;
    color: var(--primary-color) !important;
  }
}

.review-checkbox {
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--primary-color) !important;
  }
  .MuiFormControlLabel-label {
    color: $primary-color !important;
  }
  span {
    font-size: 15px !important;
  }
  .MuiTypography-root {
    font-size: 15px !important;
    color: $primary-color !important;
  }
} 

.review_Notes {
  font-family: proxima_nova !important;  
}

.main-container-full-width {
  width: 100% !important;
  height: calc(100vh - 170px);
  .page-title {
    font-weight: 300 !important;
    padding: 25px 15px !important;
  }
  .header,
  .page-title {
    padding-left: 15px !important;
    padding-right: 15px !important;
    text-transform: none;
  }
  button {
    display: none;
  }
  object {
    width: calc(100% - 30px);
    height: 98%;
  }
}
