.retrieveBank-row--wrap {
  padding: 60px 0 70px 0;
  margin-bottom: 60px;
  h6 {
    margin-top: 0;
    font-weight: $font-bold;
    margin-bottom: 20px;
  }
  .btn-group {
    margin-top: 20px;
  }
}

.warning-loan-refinance {
  margin-top: -60px;
}

.affordability__table {
  overflow: visible;
}

.period-radio-btn-group {
  white-space: nowrap;
}

.retrieve-bank-statement-section {
  display: grid;
  align-items: flex-end;
  grid-template-columns: 0.6fr 0.6fr 1fr;
  grid-gap: 30px;
  margin-bottom: 20px;
  position: relative;
  .md-btn {
    position: absolute;
    right: -10px;
  }
  .md-text-field-message-container {
    position: absolute;
  }
  &.loader-show:after {
    right: 25px;
  }
}

.text-fields___price,
.text-fields___percentage {
  &:before {
    bottom: 18px;
    color: $primary-color;
    font-weight: $font-normal;
    font-size: $font-size-sm;
    position: absolute;
  }
  &.disabled:before {
    color: $gray500;
  }
}
.text-fields___price {
  input {
    padding-left: 10px !important;
  }
  &:before {
    left: 0px;
    content: "$";
  }
}

.edit-note-textarea {
  margin: 0;
  padding: 0;
  .md-text-field-multiline-container {
    padding: 0 20px;
    border-bottom: 1px solid $gray200;
    margin-bottom: 10px;
  }
  .md-text-field-message-container {
    padding: 0 20px;
  }
}

.text-fields___percentage {
  input {
    padding-right: 20px !important;
  }
  &:before {
    bottom: 7px;
    right: 7px;
    content: "%";
  }
}

.assets-sub-field {
  display: flex;
  max-width: 540px;
  margin-bottom: 6px;
  align-items: baseline;
  label {
    width: 50%;
    display: inline;
  }
  .md-autocomplete-container {
    width: 75%;
    &.loader-show:after {
      right: -40px;
      top: 2px;
    }
    hr {
      margin: 2px 0 0px;
    }
  }
  .assets-sub-field-column {
    margin-left: 32px !important;
  }
}

.note-required {
  color: $red !important;
  display: inline-block;
  height: 30px;
}

.no-note {
  display: block;
}

.note-required-btn,
.copy-to-all-btn {
  padding: 0 !important;
  border: 0 !important;
  margin: 6px 0 !important;
  background: transparent !important;
  color: $red;
  text-transform: none !important;
  min-width: 0 !important;
  @include devicepoint(1200px) {
    // margin: 17px 0 !important;
  }
}

.copy-to-all-btn {
  margin-right: 10px !important;
  color: $md-secondary-color !important;
}

.note-required-btn {
  color: $red !important;
}

.note-accepted-btn {
  color: $md-secondary-color !important;
}

.show-details-button {
  color: $green !important;
  margin-left: 12px !important;
}

.note-btn,
.note-accepted-btn,
.copy-to-all-btn,
.note-required-btn {
  font-weight: $font-normal !important;
}

.note-btn {
  padding: 0 !important;
  border: 0 !important;
  margin: 6px 0 !important;
  background: transparent !important;
  text-transform: none !important;
  min-width: 0 !important;
  @include devicepoint(1200px) {
    // margin: 7px 0 !important;
  }
}

.md-dialog:not(.md-dialog--picker) {
  width: 700px;
  max-width: 700px;
  transform: none !important;
  top: 50px;
  left: 0;
  right: 0;
  margin: auto;
}

ul.modal-list {
  margin-bottom: 30px;
  li {
    list-style: none;
    label {
      font-weight: 400;
    }
    span {
      font-size: 90%;
      margin-left: 10px;
    }
  }
}

.modal-button {
  margin-top: 30px;
  justify-content: flex-end;
}

// modal table
.md-dialog-container {
  .md-table-column--adjusted {
    padding-right: 10px;
    padding-left: 0 !important;
  }
  .md-table-row {
    background: transparent !important;
  }
}

#note-modal {
  max-height: calc(100% - 80px);
  @include devicepoint(991px) {
    max-width: 90%;
    width: 90%;
  }
  .md-dialog-content {
    max-height: none !important;
  }
}

#history-modal {
  max-height: calc(100% - 80px);
  overflow: hidden;
  @include devicepoint(991px) {
    max-width: 90%;
    width: 90%;
  }
  .md-dialog-content {
    max-height: none !important;
  }
}

#overflow-auto {
  overflow: auto;
}
.row-deleted > td:not(.table-btn) {
  opacity: 0.5;
  pointer-events: none;
}

.margin-right {
  margin-right: 20px;
}

.margin-left {
  margin-left: 10px;
}

.applicant-documents-list {
  margin-top: 50px;
}

@media only screen and (max-width: 1100px) {
  .retrieve-bank-statement-section .select_field {
    width: 200px;
  }
}

.validation {
  font-size: 12px;
  color: $gray500;
}

.validation.error {
  color: $red;
}

.summary-row {
  font-size: inherit;
}

.summary-column {
  border-bottom: none !important;
}

.summary-column-asset,
.summary-column-income {
  text-align: right !important;
  border-bottom: none !important;
}

.summary-column-living-expenses {
  text-align: right !important;
  width: 240px !important;
}

.disabled-select-field {
  color: $gray500 !important;
  -webkit-text-fill-color: $gray500 !important;
}

.disabled-input-field {
  background-color: transparent !important;
  color: $gray500 !important;
}

.living-expenses-total-note-required-btn {
  text-align: right !important;
  width: 240px !important;
  padding-right: 30px !important;
}

.period-column {
  color: inherit !important;
  pointer-events: none;
  font-size: 15px !important;
}

.credit-card-column {
  border-bottom: none !important;
}

input.md-text--disabled:-internal-autofill-selected {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.38) !important;
}

.md-text--disabled {
  color: $gray500;
}

.input-border {
  border: none !important;
  font-size: 15px !important;
  // padding: 0 7px !important;
  // padding-right: 16px;
  text-align: right !important;
  width: 100%;
}
.input-border:focus {
  outline: none !important;
}
