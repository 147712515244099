.range-slider-wrap {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
  user-select: none;
  > div {
    width: 100%;
  }
  > span {
    -webkit-user-select: none;
    /* Safari 3.1+ */
    -moz-user-select: none;
    /* Firefox 2+ */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Standard syntax */
  }
}

.rangeslider.rangeslider-horizontal {
  height: 5px;
  border-radius: 4px;
  box-shadow: none !important;
  background: $gray100;
  margin: 28px 0 20px 0;
  position: relative;
  ul.rangeslider__labels {
    margin: 0;
    position: absolute;
    top: 0px;
    z-index: 0;
    color: $green500;
    list-style: none;
    left: 0;
    right: 0;
    li {
      position: absolute;
      @include transform(translate3d(-50%, 0, 0));
      font-size: 0;
      &:first-child {
        color: $red;
      }
      &:before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        height: 0;
        width: 0;
        margin: auto;
        bottom: 0px;
        border-bottom: 5px solid $gray100;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
      }
      &:last-child:before {
        border-top: 5px solid $gray100;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
        border-bottom: 0;
        bottom: -10px;
      }
    }
  }
  &[aria-valuemax="0"] .rangeslider__handle {
    left: 100%;
  }
}

.rangeslider:before {
  content: "";
  position: absolute;
  top: -20px;
  bottom: -20px;
  left: 0;
  right: 0;
}

.corner-align--label.default-label-color .rangeslider ul.rangeslider__labels li {
  color: $gray500 !important;
}

.corner-align--label .rangeslider ul.rangeslider__labels {
  top: auto;
  bottom: 5px;
  li {
    @include transform(translate3d(0%, 0, 0));
    padding-bottom: 22px;
    bottom: 0;
    &:first-child {
      left: auto !important;
      right: 0 !important;
      text-align: right;
      &:before {
        left: auto;
      }
    }
    &:last-child {
      left: 0 !important;
      right: auto !important;
      &:before {
        right: auto;
      }
    }
  }
}

.rangeslider__handle-label {
  position: absolute;
  bottom: -30px;
  left: -41px;
  width: 100px;
  text-align: center;
}

.corner-align--label .rangeslider__handle-label {
  left: 0;
}

.rangeslider-horizontal {
  .rangeslider__fill {
    background: $green;
    box-shadow: none !important;
    border-radius: 10px;
    top: 0;
    height: 100%;
  }
  .rangeslider__handle {
    height: 20px !important;
    border-radius: 50% !important;
    width: 20px !important;
    outline: 0 !important;
    background: $white;
    border: 1px solid $gray;
    z-index: 1;
    cursor: pointer;
    display: inline-block;
    position: absolute;
    border-radius: 0px;
    top: 50%;
    @include transform(translate3d(-50%, -50%, 0));
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.27);
  }
}

.disabled-value .rangeslider-horizontal .rangeslider__fill {
  background: $gray500;
}

.disabled-value .rangeslider-horizontal .rangeslider__handle {
  cursor: no-drop;
}

.wrong-value .rangeslider-horizontal .rangeslider__fill {
  background: $amber;
}

.error-slider .rangeslider-horizontal .rangeslider__fill {
  background: $red;
}
