.upload-header {
  @extend .grid-6-col;
  padding: 70px 0;
}

#server-upload-file-field {
  text-overflow: ellipsis;
  padding-bottom: 5px;
}

.acceptance-letter-pdf-icon {
  width: 217px;
  height: 298px;
  border: 1px solid #999;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}

.check-tick-icon {
  width: auto;
  height: 100%;
}

.check-tick-icon-autoWidth {
  width: auto;
  height: 100%;
}
.loader-column.loader-show:after {
  right: 6px;
}

.loader-file.loader-show:after {
  margin-top: 18px;
}

.download-pdf-group {
  text-align: center;
  @include devicepoint(tablet) {
    margin-bottom: 70px;
  }
  > img {
    margin-bottom: 20px;
  }
}

.acceptance-letter--section {
  h3 {
    color: $green;
    margin-top: 0;
  }
  @include devicepoint(tablet) {
    text-align: center;
    > ul {
      display: inline-block;
      text-align: left;
    }
  }
  > ul {
    margin-bottom: 43px;
    > li {
      margin-bottom: 20px;
    }
  }
  ul {
    list-style: none;
    padding-left: 50px;
    position: relative;
    &:before {
      content: "";
      width: 1px;
      bottom: 18px;
      position: absolute;
      background: $lightgray;
      left: 16px;
      top: 10px;
      opacity: 0;
    }
    > li {
      position: relative;
      &:not(.active) {
        color: $gray500;
      }
      &.active {
        color: inherit;
        .bullet-cirlce {
          background: $green;
          border-color: $green;
        }
      }
      img {
        max-width: 100px;
        vertical-align: middle;
      }
      .bullet-circle {
        content: "";
        position: absolute;
        width: 25px;
        height: 25px;
        border: 1px solid $lightgray;
        left: -46px;
        border-radius: 50%;
        top: 0px;
        background: #fff;
      }
      > ul {
        padding-left: 46px;
        margin: 15px 0 20px;
      }
      li {
        margin-bottom: 15px;
        &:before {
          width: 16px;
          height: 16px;
          top: 4px;
          left: -37px;
        }
      }
    }
  }
}

.file-inputs__upload-form {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  &__progress {
    width: 100%;
  }
  &__file-upload {
    flex-shrink: 0;
  }
  &__file-field {
    flex-grow: 1;
    width: 396px;
  }
}

.supporting-documents-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  position: relative;
  flex-wrap: wrap;
  h3 {
    margin: 0 !important;
    text-transform: uppercase;
    @include devicepoint(1200px) {
      width: 100%;
      margin-bottom: 40px !important;
    }
  }
  .upload-file-form > div {
    margin-right: 15px;
  }
}

.upload-file-form {
  padding: 0;
  min-width: 500px;
  label.md-btn {
    span.md-icon-text {
      font-weight: 400;
      padding: 0;
      line-height: 1;
    }
    .md-icon {
      display: none;
    }
  }
  hr {
    margin-bottom: 0;
  }
}

.md-dialog-footer--card {
  justify-content: flex-end;
}

.margin-right-10 {
  margin-right: 10px;
}

.attach-pdf p {
  .MuiLink-button {
    font-size: inherit;
    margin-left: 5px;
  }
}
