.md-selection-control-container {
  margin-left: -10px;
  &--inline {
    margin-right: 30px;
  }
  .md-btn--icon {
    padding: 8px;
    text-align: center;
    width: 40px;
    height: 40px;
    .md-icon {
      border: 1px solid $gray600;
      font-size: 0;
      color: transparent;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      position: relative;
    }
    &[role="radio"] {
      .md-icon {
        border-radius: 50%;
      }
      &[aria-checked="true"] {
        .md-icon:after {
          content: "";
          position: absolute;
          top: 4px;
          bottom: 4px;
          left: 4px;
          right: 4px;
          border-radius: 50%;
          background: $green;
        }
      }
    }
    &[role="checkbox"] {
      &[aria-checked="true"] {
        .md-icon:after {
          content: "done";
          position: absolute;
          font-family: "Material Icons";
          font-size: $font-size-lg;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          color: $green;
          font-weight: 900;
        }
      }
    }
  }
}

.custom-input-box {
  display: inline-block;
  height: 22px;
  line-height: 1.6;
  outline: 0;
  white-space: nowrap !important;
  &:before {
    content: "$";
  }
}
.select_field {
  width: 100%;
}

.select_field-2 {
  width: 50%;
}

.select-state {
  .md-icon-text,
  .md-icon {
    padding-bottom: 8px;
  }
}

.md-divider {
  background: $gray100;
  transform: scale(1.001);
}
.md-divider--text-field::after {
  background: $green;
}
.md-divider--text-field-error::after {
  background: $red;
}
.md-list--menu {
  width: 100%;
  left: 1px;
  margin-bottom: 50px;
}
input[type="password"] {
  width: 100% !important;
  padding-right: 30px !important;
}
.radio-group-btn {
  border: 1px solid $border-color;
  display: inline-block;
  border-radius: 2px;
  overflow: hidden;
  > div {
    display: inline-block;
    margin: 0 !important;
    height: auto;
  }
  label {
    height: 20px;
    padding: 0 10px;
    border-right: 0;
    line-height: 20px;
    > .md-fake-btn {
      display: none;
    }
  }
  input:checked + label {
    background: $md-secondary-color;
    color: $white;
  }
}
.label {
  margin-bottom: 6px;
  display: block;
  font-size: $h6;
}
.md-selection-control-group fieldset legend {
  margin-bottom: 1px;
}
.label__animation_remove {
  label {
    position: relative;
    font-size: $h6;
    top: 4px;
    transform: none;
    margin-bottom: 15px;
    display: block;
    &:not(.md-text--error) {
      color: inherit;
    }
    &.md-text--disabled {
      cursor: default;
    }
  }
  .md-list {
    top: 30px;
  }
  .md-text-field {
    margin-top: 0;
    height: 28px;
  }
}
@media screen and (min-width: 1025px) {
  .md-list-tile {
    height: 45px;
  }

  .md-list {
    padding-bottom: 0px;
    padding-top: 0px;
  }
}
.md-text-field {
  box-shadow: none;
}
.md-text-field-container .md-text-field-message-container {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}
fieldset legend,
.select_field label,
.md-floating-label,
.md-custom-label {
  cursor: pointer;
  pointer-events: auto;
}
.md-picker-container {
  .md-text-field-icon-container {
    position: relative;
    .md-icon {
      position: absolute;
      left: -2px;
      top: 1px;
    }
  }
  .md-text-field-message-container--left-icon-offset {
    padding-left: 0 !important;
  }
  label {
    left: 0;
  }
  .md-text-field-divider-container {
    margin-left: 0;
  }
  input {
    padding-left: 30px !important;
  }
}

.icon-select-field {
  .select_field {
    display: inline-block;
    width: auto;
    margin-right: 40px;
    text-align: center;
    background-repeat: no-repeat !important;
    background-position: left 11px !important;
    .md-select-field__toggle .md-select-field {
      padding-left: 30px;
    }
    &.bedroom-icon {
      background: url("../images/icon/bed-icon.svg");
    }
    &.bathroom-icon {
      background: url("../images/icon/bath-icon.svg");
    }
    &.car-icon {
      background: url("../images/icon/car-icon.svg");
    }
    hr {
      display: none;
    }
  }
  label {
    display: block;
    margin-bottom: 10px;
  }
}

.employment-modal-left-column {
  width: 51%;
  float: left;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    width: 100% !important;
  }
}

.employment-modal-job-title-column {
  width: 100%;
  float: left;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    width: 100% !important;
  }
}

.employment-modal-free-text-column {
  width: 100%;
  float: left;
  margin-top: 60px;
  @media (max-width: 991px) {
    width: 100% !important;
    margin-top: 0px;
  }
}

.employment-modal-filler-row {
  width: 92%;
  float: left;
  @media (max-width: 991px) {
    width: 100% !important;
  }
}

.employment-end-date {
  margin-left: 175px !important;
  @media (max-width: 991px) {
    width: 100% !important;
    margin-left: 0px !important;
  }
}

.employment-modal-right-column {
  width: 46%;
  float: right;
  margin-bottom: 60px;
  @media (max-width: 991px) {
    width: 100% !important;
  }
}

.employment-checkbox {
  padding-top: 11px;
  padding-bottom: 11px;
}

.driving-license-select {
  .state-label {
    display: inline-block;
    margin-bottom: 7px;
  }

  .md-paper {
    margin-top: -1px;
  }

  @media only screen and (max-width: 1024px) {
    .state-label {
      margin-bottom: 2px;
    }
  }
}

.title-select {
  @extend .driving-license-select;
}

.grid-70-30-col {
  @extend .grid-6-col;
  @include devicepoint(tablet) {
    display: grid !important;
  }
  grid-template-columns: calc(70% - 15px) calc(30% - 15px) !important;
  .md-icon-separator {
    line-height: inherit;
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    height: 30px;
    padding: 6px 7px 6px 0px;
    .md-icon {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
}

.early-repayment-dropdown {
  .md-icon-separator {
    line-height: inherit;
    display: inline-block;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    height: 30px;
    padding: 6px 7px 6px 0px;
    .md-icon {
      position: absolute;
      right: 0;
      top: 5px;
    }
  }
  display: grid !important;
}

.state-label {
  display: inline-block;
  margin-bottom: 7px;
}

@media only screen and (max-width: 1024px) {
  .state-label {
    margin-bottom: 2px;
  }
}
