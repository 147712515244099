.md-menu--select-field {
  .md-text--theme-primary:not(.md-floating-label) {
    color: $md-secondary-color;
  }
  .md-list--menu {
    &:not(#simple-pagination-pagination-menu-options) {
      width: auto;
      min-width: 100%;
      margin-bottom: 0;
    }
  }
}
.md-select-field--btn.md-select-field--btn {
  padding-left: 0;
}
