.main-container.hide-lras + .side_widget .metrics-panel {
  // hide the lras from dashboard
  display: none;
}

.metrics-panel-hide-lras {
  display: none;
}

.side_widget ::-webkit-scrollbar-thumb {
  background: $md-secondary-color;
  visibility: hidden;
}

.side_widget ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.side_widget:hover {
  ::-webkit-scrollbar-thumb {
    visibility: visible;
  }
}

.side_widget {
  position: fixed;
  transform: translate(75%, 0);
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  z-index: 10;
  transition: all 600ms ease;
  background: $white;
  @media only screen and (min-width: 1101px) and (max-width: 1300px) {
    transform: translate(78%, 0);
  }
  @include devicepoint(1100px) {
    transform: translate(100%, 0);
    &.review-active {
      left: 0 !important;
      font-size: 14px;
      padding-left: 160px;
      @include devicepoint(991px) {
        padding-left: 130px;
      }
      .score_widget {
        z-index: 2;
        &:not(.widget-expend) {
          left: 0;
        }
        &.widget-expend {
          left: 0;
        }
      }
    }
  }
}

.score_widget {
  height: 100vh;
  overflow: auto;
  width: 25%;
  background: $gray;
  color: $white;
  padding: 37px 30px 50px 30px;
  -webkit-overflow-scrolling: touch;
  @media only screen and (min-width: 992px) and (max-width: 1300px) {
    width: 22%;
    padding: 37px 20px 50px 20px;
  }
  .widget--close-btn {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
  @include devicepoint(1100px) {
    padding: 50px 20px;
    position: absolute;
    transition: all 600ms ease;
    .ais-SearchBox-form {
      transition: all 600ms ease;
    }
    &:not(.widget-expend) {
      width: 160px;
      left: -160px;
      @include devicepoint(tablet) {
        width: 130px;
        left: -130px;
      }
      .progress-bar {
        display: flex;
        flex-direction: column-reverse;
        flex-wrap: wrap;
        margin-bottom: 10px;
        text-align: center;
        .progress-bar-fll-container-tab-screeen {
          background: #555;
        }
        .progress-bar-fill-tab-screen {
          min-width: 5px;
          max-width: 100%;
          height: 2px;
          background: $green;
          transition: 0.3s;
          &.overLimit {
            background: $red;
          }
          &.limitWarning {
            background: $yellow !important;
          }
        }
        .progress-bar-fill {
          background: transparent;
          width: 100% !important;
          height: auto;
          text-align: inherit !important;
          &:before {
            display: none;
          }
          .progress-bar-label {
            position: static;
            font-size: $h2 - 4px !important;
            display: block;
            padding: 0;
            border-bottom: 2px solid $green;
            margin-bottom: 10px;
            @include devicepoint(tablet) {
              font-size: $h4 !important;
            }
          }
        }
        .progress-bar-container {
          border: 0;
        }
        h6 {
          width: 100%;
          margin: 0;
        }
      }
      .pie-chart-container {
        display: none;
      }
      .papers__container {
        padding-top: 30px;
      }
      .ais-SearchBox-form {
        text-align: center;
        padding: 40px 0;
        background: #232323 !important;
        border: 0;
        margin: 0 -20px;
        &:after {
          content: "Help";
        }
        input {
          display: none;
        }
        > .ais-SearchBox-submit {
          position: static;
          display: block;
          margin: auto;
        }
        .ais-SearchBox-submitIcon {
          width: 40px;
          height: 40px;
        }
      }
      .help_heading,
      .help_content {
        display: none;
      }
    }
    &.widget-expend {
      width: 260px;
      left: -260px;
      .widget--close-btn {
        display: block;
      }
    }
  }
  .metrics-panel {
    h5 {
      display: none;
    }
    .income-expense-heading{
      display: unset;
      color: inherit;
    }
    .progress-bar {
      color: $white;
      visibility: hidden;
      display: none;
      .progress-bar-container {
        border-color: $gray600;
      }
      .progress-bar-fill {
        text-align: right;
      }
      .progress-bar-label {
        font-size: 90%;
        bottom: -24px;
        padding-top: 6px;
        position: static;
        display: block;
      }
    }
    h6 {
      color: inherit;
      @include devicepoint(1280px) {
        font-size: $h6 - 2px;
      }
    }
    .progress-bar-fill:before {
      border-right-color: $gray !important;
    }
    .visible-in-sidebar {
      display: block;
    }
    .hide-in-sidebar {
      display: none;
    }
    .md-paper {
      padding: 0;
      background: none;
      color: inherit !important;
      h4 {
        color: inherit;
        strong {
          font-size: $h2 - 4px;
          font-weight: $font-normal;
          @include devicepoint(tablet) {
            font-size: $h4 !important;
          }
        }
      }
    }
    .papers__container {
      display: none;
    }
    .pie-chart-container {
      visibility: hidden;
      display: none;
    }
    @include devicepoint(1280px) {
      span.recharts-legend-item-text {
        font-size: $font-size-xs - 2px;
      }
      .recharts-default-legend {
        display: block;
        li {
          margin-right: 5px !important;
        }
      }
    }
  }
  .papers__container {
    .md-paper {
      opacity: 0;
    }
  }
}

.sidebar_show-score {
  .progress-bar,
  .pie-chart-container,
  .md-paper {
    animation-name: showScore;
    animation-duration: 2s;
    animation-fill-mode: forwards;
  }
  .progress-bar:nth-of-type(2) {
    display: block !important;
    visibility: visible !important;
    opacity: 0;
  }
  @include devicepoint(1100px) {
    .score_widget:not(.widget-expend) {
      .progress-bar:nth-of-type(2) {
        display: flex !important;
      }
    }
  }
}

.sidebar_show-nsr {
  .progress-bar:nth-of-type(3) {
    display: block !important;
    visibility: visible !important;
    opacity: 0;
  }
  @include devicepoint(1100px) {
    .score_widget:not(.widget-expend) {
      .progress-bar:nth-of-type(3) {
        display: flex !important;
      }
    }
  }
}

.sidebar_score-animation-complete {
  .progress-bar:nth-of-type(1),
  .progress-bar:nth-of-type(4),
  .progress-bar:nth-of-type(5) {
    display: block !important;
    visibility: visible !important;
    opacity: 0;
  }
  @include devicepoint(1100px) {
    .score_widget:not(.widget-expend) {
      .progress-bar:nth-of-type(1),
      .progress-bar:nth-of-type(4),
      .progress-bar:nth-of-type(5) {
        display: flex !important;
      }
    }
    .papers__container {
      .md-paper {
        opacity: 1;
        &:nth-child(1) {
          transition-delay: 2.5s;
        }
        &:nth-child(2) {
          transition-delay: 3s;
        }
        &:nth-child(3) {
          transition-delay: 3.5s;
        }
      }
    }
  }
  .progress-bar:nth-of-type(4) {
    animation-delay: 1.5s;
  }
  .progress-bar:nth-of-type(5) {
    animation-delay: 2s;
  }
  .pie-chart-container {
    animation-delay: 2.5s;
  }
  @media only screen and (min-width: 1101px) {
    .pie-chart-container {
      display: block !important;
      visibility: visible !important;
      opacity: 0;
      animation-delay: 1s;
    }
  }
  .score_widget.widget-expend {
    .pie-chart-container {
      display: block !important;
      visibility: visible !important;
      opacity: 0;
      animation-delay: 1s;
    }
  }
  @include devicepoint(1100px) {
    .score_widget {
      &.widget-expend {
        .pie-chart-container {
          animation-delay: 0s;
        }
      }
      &:not(.widget-expend) {
        .papers__container {
          display: block !important;
          visibility: visible !important;
        }
      }
    }
  }
}

@keyframes showScore {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.score_page {
  width: 75%;
  @media only screen and (min-width: 1101px) and (max-width: 1300px) {
    width: 78%;
  }
  @include devicepoint(1100px) {
    width: 100%;
  }
  .footer-navigation {
    position: relative;
    width: 100%;
  }
  .review-page {
    height: calc(100vh - 80px);
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: auto;
  }
  .property-account-summary {
    margin: 0 -50px !important;
    padding: 60px 50px !important;
    @media only screen and (min-width: 1500px) {
      margin: 0 -60px !important;
      padding: 60px 60px !important;
    }
    @media only screen and (min-width: 1101px) and (max-width: 1300px) {
      margin: 0 -20px !important;
      padding: 60px 20px !important;
    }
    @media only screen and (max-width: 1100px) {
      margin: 0 -30px !important;
    }
    @media only screen and (max-width: 800px) {
      margin: 0 -20px !important;
    }
  }
}

.hits_display {
  display: none;
}

.help_heading {
  color: $md-secondary-color;
  font-size: $font-size-lg;
  border-bottom: 1px solid $darkgray;
  padding: 15px 0 12px 0;
}

.help_container {
  ul {
    padding-left: 20px;
    cursor: pointer;
    .material-icons {
      font-size: inherit;
      vertical-align: middle;
    }
  }
  a {
    color: inherit;
    &:hover {
      color: $green !important;
      opacity: 1 !important;
    }
  }
}

.help_content {
  color: $white;
  padding-top: 10px;
}

.sidebar-expand-list{
  list-style-type: none;
  margin-left: 18px;
}


.MuiCircularProgress-colorPrimary{
  color: $green !important;
  &.overLimit {
    color: $red !important;
  }
}

.income-expense-bar-chart svg{
  height: 10px;
}

.MuiExpansionPanel-root.Mui-expanded{
  margin:0px !important
}

.loan-metric-accordion-wrapper{
  padding: 10px;
}

.barchart-label-wrapper, .loan-metric-accordion-wrapper {
  display: grid;
  grid-template-columns: auto auto;
}