* {
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
  background: #fff;
}

body {
  font-size: $base-font-size;
  font-family: $Primary-font !important;
  line-height: 1.6;
  text-rendering: auto;
  color: $primary-color;
  letter-spacing: 0.02em;
}

h2,
.md-headline,
h3,
.md-title,
h4,
.md-subheading-2,
h5,
.md-subheading-1,
h6,
.md-body-2,
p,
.md-body-1 {
  color: inherit;
}

legend.md-subheading-1 {
  margin-bottom: 5px;
}

li a:not(.md-btn),
.team a,
section a:not([class]) {
  @include transition(all 0.2s ease);
  &:hover {
    opacity: 0.5;
  }
}

a {
  text-decoration: none;
  cursor: pointer !important;
}

.container {
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
  @include devicepoint("tablet") {
    max-width: 670px;
  }
  @include devicepoint("767px") {
    max-width: 550px;
    padding: 0 20px;
  }
  &:after {
    content: "";
    display: block;
    clear: both;
  }
}

img {
  max-width: 100%;
  height: auto;
}

// typography
p,
button,
input,
textarea {
  font-family: inherit !important;
}

p {
  font-size: inherit;
}

.text-muted {
  color: $gray500 !important;
}

h1,
h2,
h3 {
  margin-top: 10px;
  margin-bottom: 25px;
  font-weight: $font-light;
  @include devicepoint(phone) {
    margin-bottom: 15px;
  }
}

h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: $font-normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $primary-color;
  line-height: 1.3;
  font-family: inherit !important;
}

h1 {
  font-size: $h1;
}

h2 {
  font-size: $h2;
}

h3 {
  font-size: $h3;
}

h4 {
  font-size: $h4;
}

h5 {
  font-size: $h5;
}

h6,
.heading-6-width-border {
  font-size: $h6;
}

// @pat css
.md-body-1,
.md-body-2,
.md-subheading-1,
.md-subheading-2,
h4,
h5,
h6,
p {
  letter-spacing: 0.02em;
}

// .md-body-1, .md-body-2, .md-caption, .md-subheading-1, .md-subheading-2, caption, h4, h5, h6, p {
//   margin-bottom: 0px;
// }
.md-text {
  color: $primary-color;
}

//===================
.heading-6-width-border {
  border-bottom: 1px solid $primary-color;
  margin: 0;
  padding-bottom: 10px;
}

.text-bold {
  font-weight: $font-bold !important;
}

.text-green {
  color: $green;
}

.text-amber {
  color: $amber;
}

.text-red {
  color: $red;
}
.bg-red {
  color: $red;
}
.md-sm-text-left {
  text-align: left !important;
}
.group-row {
  margin-bottom: 60px;
}

.group-row .accounttype-radio .md-selection-control-container--inline,
.group-row .accountpurpose-radio .md-selection-control-container--inline {
  margin-right: 20px;
}

.grid-6-col {
  @include grid(calc(50% - 15px) calc(50% - 15px));
  @include grid-gap(30px);
  @include devicepoint(tablet) {
    display: block;
  }
}

.grid-row-2 {
  grid-row: 2;
}

.grid-2by-3column {
  @extend .contact-detail-section;
}

.half-col {
  width: 50%;
}

.width100 {
  width: 100px;
}

.width30 {
  width: 30px;
}

.width-200 {
  max-width: 200px;
}

.w-200 {
  width: 200px;
}

.width-130 {
  max-width: 130px;
}

.width-150 {
  max-width: 150px;
}

.minWidth-270 {
  min-width: 270px;
}

.width60 {
  width: 60%;
}

.error-block-icon1 {
  color: transparent !important;
  background: url("../images/icon/cross-icon.svg");
  background-repeat: no-repeat;
  font-size: 39px !important;
}
.error-block-icon2 {
  color: transparent !important;
  background: url("../images/icon/cross-icon.svg");
  background-repeat: no-repeat;
  font-size: 39px !important;
  margin-right: 8px;
}

.column-width2 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 236px;
  
  @media only screen and (min-width: 1500px) {
    max-width: 360px;
  }
}

.upload-status {
  display: inline;
}

.upload-status-icon {
  height: 18px;
  float: left;
  margin-right: 10px;
}

.column-width135 {
  width: 135px;
}

.information {
  margin-top: 20px;
  margin-right: 20px;
}

.tick-span {
  display: inline-flex;
  align-items: center;
  margin-left: 15px;
}

.font-span {
  margin-bottom: 0px;
  margin-top: 6px;
  margin-left: 10px;
}

.tick {
  display: flex;
  margin-bottom: 10px;
}

.upload-back-button {
  text-transform: none !important;
  margin-top: 35px;
}

.mb-5 {
  margin-bottom: 5px;
}

// react md class
.md-text-field,
.md-selection-control-label {
  font-size: $base-font-size !important;
  letter-spacing: 0.02em;
}

.md-subheading-2,
.md-subheading-1 {
  font-size: $font-size-sm + 1px;
}

.md-tile-text--primary,
.md-tile-text--secondary {
  font-size: inherit;
}

.md-display-3,
.md-display-4,
h3,
.md-title {
  white-space: normal;
}

.md-divider--text-field {
  margin-bottom: 4px;
  margin-top: 0;
}

.md-selection-control-label {
  align-items: baseline;
}

// loader
.loader-show {
  position: relative;
  &:after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 10px;
    background: url("../images/spinner.svg");
    background-size: contain;
    border-radius: 50%;
    @include animation(0s, 2s, spin);
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
.fallback-spinner {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 35%;
  left: 40%;
  background: url("../images/spinner.svg");
  background-size: contain;
  border-radius: 50%;
  @include animation(0s, 2s, spin);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.public-fallback-spinner {
  width: 20px;
  height: 20px;
  position: fixed;
  top: 35%;
  left: 50%;
  background: url("../images/spinner.svg");
  background-size: contain;
  border-radius: 50%;
  @include animation(0s, 2s, spin);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.error-block-icon,
.tick-icon,
.tick-icon-amber {
  color: transparent !important;
  background-size: contain;
  background-repeat: no-repeat;
}

.tick-icon {
  background-image: url("../images/icon/ticks.svg");
}

.tick-icon-amber {
  background-image: url("../images/icon/amber-tick.svg");
}

.error-block-icon {
  color: transparent !important;
  background: url("../images/icon/cross-icon.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

// snackbar
.error-snackbar,
.success-snackbar {
  text-align: center !important;
  justify-content: center !important;
  left: 50%;
  right: 0;
  max-width: 100%;
  width: 100%;
  z-index: 1500 !important;
}

.success-snackbar {
  background: $green !important;
}

.error-snackbar {
  background: $red !important;
}

@include keyframes(spin) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.visible-in-sidebar {
  display: none;
}

@media only screen and (min-width: 992px) {
  .visible-sm {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .hide-md {
    display: none;
  }
}
@media only screen and (max-width: 991px) {
  .hide-sm {
    display: none;
  }
  .tablet-width-full {
    max-width: 100%;
    width: 100%;
  }
  .header-broker-name {
    word-spacing: 1111px;
  }
  .label__animation_remove label {
    font-size: 14px;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 768px) {
  .visible-xs {
    display: none;
  }
}

@include devicepoint("767px") {
  .hide-xs {
    display: none;
  }
}

.green-bg {
  background: $green500;
  .default-btn {
    border-color: $black !important;
    color: $black !important;
  }
  .md-divider {
    background: $gray;
  }
  .md-selection-control-container .md-btn--icon {
    .md-icon {
      border-color: $black !important;
    }
    &[role="radio"] .md-icon:after {
      background: $black !important;
    }
    .md-icon:after {
      color: $black !important;
    }
  }
  .loader-show:after {
    background-image: url("../images/white-spinner.svg");
  }
  .md-text-field:-webkit-autofill,
  .md-text-field:-webkit-autofill:focus {
    box-shadow: 0 0 0 50px $green500 inset !important;
  }
  .md-btn:not(.md-btn--icon):not(.md-calendar-date--btn):not(.md-picker-control):not([disabled]).md-background--secondary {
    background: $green !important;
  }
}

.gray-bg {
  .md-text-field:-webkit-autofill,
  .md-text-field:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 50px $gray50;
  }
}

.modal-sm {
  .md-dialog {
    width: 300px !important;
    padding: 20px;
    .md-dialog-content--padded {
      padding: 0px;
    }

    footer {
      padding: 10px 0 0;
      .md-btn + .md-btn {
        margin-left: 10px;
      }
    }
  }
}

.save-discard-dialog {
  .md-dialog {
    width: 430px !important;
  }
  .dialog--footer {
    justify-content: flex-end;
  }
}

.loading-wrap {
  text-align: center;
  .loader-show {
    width: 20px;
    height: 30px;
    display: block;
    margin: auto;
  }
}

.loading-wrap-document {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  .loader-show {
    width: 20px;
    height: 30px;
    display: block;
    margin: auto;
  }
}

.upload-loader {
  margin: 0px !important;
}

.btn-header-row {
  text-align: right;
  margin-bottom: 45px;
  margin-top: -86px;
}

.no-wrap {
  white-space: nowrap !important;
}

.disable-tag {
  display: contents;
}

.border0 {
  border-bottom: 0;
}

.valid-tick-icon,
.credit-check-tick-icon,
.statistical-valuation-success-tick {
  .cls-2 {
    fill: none;
    stroke: $green;
    stroke-width: 2px;
  }
  .cls-3 {
    fill: $green;
    fill-rule: evenodd;
  }
}

.credit-check-tick-warning-icon {
  .cls-2 {
    fill: none;
    stroke: $amber;
    stroke-width: 2px;
  }
  .cls-3 {
    fill: $amber;
    fill-rule: evenodd;
  }
}

.cross-icon-cls-1 {
  fill: none;
  stroke: #e94133;
  stroke-width: 2px;
}

.cross-icon-cls-2 {
  fill: #e94133;
  fill-rule: evenodd;
}

.MuiTypography-colorPrimary,
.MuiPickersYear-root:focus {
  color: $green !important;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.MuiAppBar-positionFixed {
  position: relative !important;
}

.pdf-canvas {
  text-align: center;
}

.preview-document-heading {
  text-align: center;
  position: relative;
  font: inherit;
  width: -webkit-fill-available;
  font-weight: 300;
  font-family: inherit;
  font-size: x-large;
}

.view-document-icon {
  color: #fff !important;
}

.display-inline {
  display: inline;
  padding: 15px;
  .react-json-view {
    padding-left: 15px !important;
  }
}

#roles-toggle {
  span.md-icon-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 28px;
  }
}
