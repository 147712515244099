@import "~react-md/src/scss/react-md";
// theme color

$gray: #333;
$red: #e05a71;
$blue: #008ff7;
$green: #47c68f;
$green500: #52e2a3;
$amber: #ffb56b;
$yellow: #ffff95;
$salem: #47c68f;
$white: #fff;
$black: #000;
$darkgray: #666;
$lightgray: #ccc;
$gray300: #e0e0e0;
$gray100: #bbbbbb;
$gray200: #eee;
$gray400: #dddddd;
$gray50: #efefef;
$gray500: #999999;
$gray600: #777;

$border-color: $gray400;

$md-light-theme: true; // optional for light theme
$md-primary-color: $blue;
$md-secondary-color: $green;
$md-tertiary-color: $md-grey-300;
$primary-color: $black;
$error-color: $red;
$md-error-color: $red;
$md-text-field-error-color: $md-error-color !default;

/*-----------------------------------*/

// When the primary or secondary colors are not part of material design, you will need to set
// some fallback colors for the the following components:
$md-data-table-contextual-fallback-color: rgba($green, 0.32);
$md-linear-progress-fallback-color: rgba($green, 0.54);
$md-switch-fallback-color: rgba($green, 0.87);
$md-slider-warn-md: false;
$md-colors-warn-md: false;
/*-----------------------------------*/

/*-----------------------------------*/
// Material UI typography setting
$md-typography-extended: false !default;
$md-typography-include-text-container: true !default;
$md-typography-include-utilities: true !default;

/*-----------------------------------*/
$md-font-name: "proxima_nova" !default;
$Primary-font: "proxima_nova" !default; // font name
$md-font-family: "#{$Primary-font}", sans-serif !default;
$md-display-4-font-size: 112px !default; // type: number
$md-display-4-line-height: 128px !default; // type: number

$md-display-3-font-size: 56px !default; // type: number
$md-display-3-line-height: 84px !default; // type: number

$md-display-2-font-size: 45px !default; // type: number
$md-display-2-line-height: 48px !default; // type: number

$md-display-1-font-size: 34px !default; // type: number
$md-display-1-line-height: 40px !default; // type: number

//custom variable

$base-color: $gray;

$h1: 46px;
$h2: 34px;
$h3: 28px;
$h4: 24px;
$h5: 18px;
$h6: 15px;

$display-5-font-size: 72px;
$display-4-font-size: 56px;
$display-3-font-size: 37px;

$font-size-lg: 18px;
$font-size-normal: 15px;
$font-size-sm: 14px;
$font-size-xs: 12px;

$base-font-size: $font-size-normal;

// font weight
$font-heavy: 900;
$font-bold: 600;
$font-semibold: 500;
$font-normal: 400;
$font-light: 300;

@include react-md-everything;
