// CSS 3 transform
@mixin transform($prop) {
  transform: $prop;
  -webkit-transform: $prop;
  -moz-transform: $prop;
}
@mixin transform-origin($prop) {
  transform-origin: $prop;
  -webkit-transform-origin: $prop;
  -moz-transform-origin: $prop;
}
