.solution-page {
  .fullwidth-section {
    margin-top: 10px !important;
  }
}

.loan_amount--section,
.cash_out_amount--section {
  > .range-slider-wrap {
    width: calc(100% - 160px);
  }
}
.interest__only__term--section > .range-slider-wrap {
  width: calc(100% - 160px);
  margin-bottom: -25px !important;
}
.offset-checkbox {
  margin-bottom: 60px;
}
.rate-term-slider {
  display: flex;
  align-content: flex-start;
  margin-bottom: 60px;
}

.cash_out_amount--section {
  .range-slider-wrap > span:first-child {
    width: 20px !important;
  }
  .rangeslider.rangeslider-horizontal[aria-valuenow="0"] .rangeslider__handle {
    margin-left: -5px;
  }
  .rangeslider__handle {
    @include transform(translate3d(-42%, -50%, 0));
  }
}
.loan-term-section {
  > .range-slider-wrap {
    width: 40%;
    margin-right: 40px;
    @include devicepoint(tablet) {
      width: calc(100% - 160px);
      margin-right: 0px;
    }
  }
}
.loan_amount--section,
.loan-term-section,
.interest__only__term--section,
.cash_out_amount--section {
  display: flex;
  flex-wrap: wrap;
  > form .md-text-field-container {
    margin-right: 30px;
  }

  .range-slider-wrap {
    margin-bottom: 45px;
  }

  legend {
    margin-bottom: 0;
  }
}
.loan_amount--section--spacing-remove .range-slider-wrap {
  margin-bottom: -25px;
}
.loan-amount-slider {
  width: calc(100% - 160px);
  font-size: 15px;
  @include devicepoint(tablet) {
    width: 100%;
  }
  > label {
    text-align: right;
    display: block;
  }
}
.rang-slide--label-fixed-width {
  > span {
    &:last-child {
      width: 100px;
      text-align: right;
    }
    &:first-child {
      width: 80px;
    }
  }
}
.interest__only__term--section .range-slider-wrap > span {
  &:first-child {
    text-align: right;
  }
  &:last-child {
    text-align: left;
  }
}

.io-disable input[value="IO"],
.offset-disable input[value*="Offset"] {
  & + label {
    pointer-events: none;
    opacity: 0.5;
  }
}

.offset-checkbox-section {
  margin-bottom: 60px;
}

@include devicepoint(tablet) {
  .loan_amount--section {
    .width-130 {
      max-width: 100%;
    }
    > div:first-child {
      margin-right: 0;
    }
    > .range-slider-wrap {
      width: 100%;
    }
  }
}

// icon
.chart-icon---1,
.chart-icon---2 {
  fill: $white;
}

.chart-icon---2 {
  fill-rule: evenodd;
}
.icon-row {
  display: flex;
  .icon-coloumn {
    margin-right: 60px;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-top: 20px;
    justify-content: space-between;
    @include devicepoint(tablet) {
      margin-right: 40px;
    }
    img {
      margin-bottom: 10px;
      @include devicepoint(tablet) {
        height: 50px;
      }
    }
  }
}
