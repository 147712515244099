#branding {
  width: 100% !important;
  background: $gray;
  color: $white;
  margin-bottom: 30px;
  z-index: 0;
  .ace_cursor {
    color: $white;
  }
  .ace_print-margin {
    left: 100% !important;
  }
}

.branding-page {
  margin-bottom: 10px;
}
.margin-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}
