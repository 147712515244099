.broker-name-section {
  @extend .applicant-name;
}
.statusSection {
  @include grid(auto 1fr);
  @include grid-gap(10px 50px);
  background: #efefef !important;
  @extend .full-width--row;
  @extend .group-row;
  padding: 50px;
  .label {
    margin: 0;
  }
}
.admin-tabs-header {
  li {
    padding-right: 0 !important;
  }
}
.originator-address-section {
  .md-text-field-container,
  .select_field {
    margin-top: 30px;
  }
}
.originator-address-row {
  @extend .contact-detail-section;
}
