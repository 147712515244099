@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-black-webfont.eot");
  src: url("../fonts/proximanova-black-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-black-webfont.woff") format("woff"),
    url("../fonts/proximanova-black-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-black-webfont.svg#proxima_novablack") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-blackit-webfont.eot");
  src: url("../fonts/proximanova-blackit-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-blackit-webfont.woff") format("woff"),
    url("../fonts/proximanova-blackit-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-blackit-webfont.svg#proxima_novablack_italic") format("svg");
  font-weight: 800;
  font-style: oblique;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-extrabld-webfont.eot");
  src: url("../fonts/proximanova-extrabld-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-extrabld-webfont.woff") format("woff"),
    url("../fonts/proximanova-extrabld-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-extrabld-webfont.svg#proxima_novaextrabold") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-extrabldit-webfont.eot");
  src: url("../fonts/proximanova-extrabldit-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-extrabldit-webfont.woff") format("woff"),
    url("../fonts/proximanova-extrabldit-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-extrabldit-webfont.svg#proxima_novaextrabold_italic") format("svg");
  font-weight: 700;
  font-style: oblique;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-bold-webfont.eot");
  src: url("../fonts/proximanova-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-bold-webfont.woff") format("woff"),
    url("../fonts/proximanova-bold-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-boldit-webfont.eot");
  src: url("../fonts/proximanova-boldit-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-boldit-webfont.woff") format("woff"),
    url("../fonts/proximanova-boldit-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-boldit-webfont.svg#proxima_nova_rgbold_italic") format("svg");
  font-weight: 600;
  font-style: oblique;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-semibold-webfont.eot");
  src: url("../fonts/proximanova-semibold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-semibold-webfont.woff") format("woff"),
    url("../fonts/proximanova-semibold-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-semibold-webfont.svg#proxima_novasemibold") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-semiboldit-webfont.eot");
  src: url("../fonts/proximanova-semiboldit-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-semiboldit-webfont.woff") format("woff"),
    url("../fonts/proximanova-semiboldit-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-semiboldit-webfont.svg#proxima_novasemibold_italic") format("svg");
  font-weight: 500;
  font-style: oblique;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-regular-webfont.eot");
  src: url("../fonts/proximanova-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-regular-webfont.woff") format("woff"),
    url("../fonts/proximanova-regular-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-regular-webfont.svg#proxima_nova_rgregular") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-regularit-webfont.eot");
  src: url("../fonts/proximanova-regularit-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-regularit-webfont.woff") format("woff"),
    url("../fonts/proximanova-regularit-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-regularit-webfont.svg#proxima_novaregular_italic") format("svg");
  font-weight: 400;
  font-style: oblique;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-lightit-webfont.eot");
  src: url("../fonts/proximanova-lightit-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-lightit-webfont.woff") format("woff"),
    url("../fonts/proximanova-lightit-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-lightit-webfont.svg#proxima_novalight_italic") format("svg");
  font-weight: 300;
  font-style: oblique;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-light-webfont.eot");
  src: url("../fonts/proximanova-light-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-light-webfont.woff") format("woff"),
    url("../fonts/proximanova-light-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-light-webfont.svg#proxima_novalight") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-thin-webfont.eot");
  src: url("../fonts/proximanova-thin-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-thin-webfont.woff") format("woff"),
    url("../fonts/proximanova-thin-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-thin-webfont.svg#proxima_novathin") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "proxima_nova";
  src: url("../fonts/proximanova-thinit-webfont.eot");
  src: url("../fonts/proximanova-thinit-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/proximanova-thinit-webfont.woff") format("woff"),
    url("../fonts/proximanova-thinit-webfont.ttf") format("truetype"),
    url("../fonts/proximanova-thinit-webfont.svg#proxima_novathin_italic") format("svg");
  font-weight: 200;
  font-style: oblique;
}
