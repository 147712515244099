.review-page {
  background: $white;
  padding: 0 50px;
  @media only screen and (min-width: 1101px) and (max-width: 1300px) {
    padding: 0 20px;
  }
  .group-row {
    margin-bottom: 0 !important;
    padding-bottom: 60px;
  }
  > .header {
    margin-left: -50px;
    margin-right: -50px;
    padding-left: 50px;
    padding-right: 50px;
    @media only screen and (min-width: 1101px) and (max-width: 1300px) {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .review-page_loan-amount {
    text-align: center;
    text-transform: uppercase;
    .md-text-field-container {
      min-width: 430px;
      margin: 0px auto 47px auto;
      width: 33%;
      input {
        text-align: center;
        font-size: 72px !important;
        height: auto;
        color: inherit;
        font-weight: $font-light;
      }
      .md-floating-label {
        top: 125px !important;
        color: $primary-color;
      }
      .md-divider {
        background: $black;
      }
    }
  }
  .page-title {
    padding: 60px 0 0 0;
  }
  .review-page .loan_amount--section .text-fields___price:before {
    top: 7px;
    bottom: auto;
  }
  > .md-grid {
    padding-left: 0;
    padding-right: 0;
    margin-left: -8px;
    margin-right: -8px;
    @include devicepoint(991px) {
      display: block;
      > div {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
  @media only screen and (min-width: 1500px) {
    padding: 0 60px;
    > .header {
      margin-left: -60px;
      margin-right: -60px;
      padding-left: 60px;
      padding-right: 60px;
    }
  }
  @media only screen and (max-width: 1100px) {
    padding: 0 30px;
    > .header {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media only screen and (max-width: 991px) {
    padding: 0 20px;
    > .header {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
.review-account--table {
  margin-bottom: 70px;
  td,
  th {
    min-width: 0 !important;
    max-width: 100% !important;
    padding: 9px 10px !important;
    min-height: 0 !important;
    height: auto;
    font-weight: $font-normal;
    font-size: $base-font-size;
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
  th {
    color: inherit;
    padding-top: 0 !important;
    &:not(:last-of-type).md-text-right {
      width: 140px !important;
    }
    &:last-of-type.md-text-right {
      width: 70px !important;
    }
  }

  tr.invalid {
    color: $red;
  }
}
//repayments cards
.card-box {
  padding-bottom: 20px;
  margin: 0;
  text-align: center;
  small {
    display: block;
    font-size: $font-size-sm;
  }
  &.visible-sidebar {
    display: none;
  }
}
.review-page {
  .review-loan-amount {
    text-align: center;
    display: table;
    margin: 70px auto 25px;
    clear: both;
    h2 {
      font-size: $display-5-font-size;
      margin: 0 0 10px;
      border-bottom: 1px solid $primary-color;
      padding: 0 40px;
    }
  }

  .range-slider-wrap {
    margin-bottom: 0;
    .rangeslider__handle-label {
      display: none;
    }
  }
  .papers__container {
    display: grid;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    text-transform: uppercase;
    padding-top: 15px;
    margin-top: 0;
    margin-bottom: 36px;
    > .card-box {
      strong {
        font-size: $display-4-font-size;
        border-bottom: 1px solid $primary-color;
        margin-bottom: 10px;
        display: block;
        font-weight: $font-light;
        span {
          font-size: $display-3-font-size;
        }
      }
      h4 {
        margin: 0;
      }
    }
  }
  .aproved-loan-message {
    text-align: center;
  }
  .review-score-section {
    padding-top: 50px;
  }
}

// pie chart
.recharts-default-legend {
  display: grid;
  grid-template-columns: auto auto;
  font-size: $font-size-xs;
  text-align: left !important;
}
.pie-chart-container {
  position: relative;
  width: 100%;
  margin: auto;
}
//progress bars
.progress-bar-container {
  background: transparent;
  border-bottom: 1px solid $lightgray;
}

.progress-bar-fill {
  height: 7px;
  position: relative;
  width: 5px;
  min-width: 5px;
  max-width: 100%;
  background: $green;
  transition: 0.3s;
  &.overLimit {
    background: $red;
  }
  &.limitWarning {
    background: $yellow;
  }
  &::before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-right: 10px solid white;
    border-bottom: 8px solid transparent;
    right: 0;
    bottom: 0;
  }
  .progress-bar-label {
    position: absolute;
    padding-top: 6px;
    left: 100%;
  }
}

.progress-bar {
  margin-bottom: 40px;
  #progress-bar-heading {
    margin-bottom: 5px !important;
  }
}

//property-account-summary
.property-account-summary {
  margin: 0px -30px !important;
  padding: 60px 30px !important;

  &:not(.white-bg) {
    background: $gray50;
  }
  h3 {
    margin-bottom: 60px;
    margin-top: 0;
    text-transform: uppercase;
  }
  h6 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  .property-list {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
    // @media only screen and (min-width: 1200px){
    //   @include grid(1fr 1fr);
    //   @include grid-gap(50px);
    // }

    // &.property-fullWidth--view{
    //   @include grid(1fr 1fr);
    //   @include grid-gap(20px);
    //   padding-right: 10px;
    //   &:not(:last-child){
    //     margin-bottom: 30px;
    //   }
    //   figure{
    //     margin-bottom: 0;
    //   }
    //   h6{
    //     margin-top: 0;
    //   }
    //   .list-table-layout{
    //     @include grid(20px auto);
    //     span{
    //       .icon{
    //         margin-right: 0;
    //       }
    //       padding-left: 0;
    //       padding-right: 0;
    //       text-align: inherit !important;
    //     }
    //   }
    // }
    figure {
      margin-bottom: 40px;
      img {
        width: 100%;
        object-fit: cover;
        border: 1px solid $gray500;
        height: 100%;
      }
    }
    .list-table-layout {
      margin-bottom: 30px;
      .icon {
        margin-right: 10px;
        width: 15px;
        object-fit: none;
        object-position: left;
        vertical-align: middle;
      }
    }
  }
}

.statistical-message {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  h6 {
    padding-left: 0px !important;
    display: flex;
    align-items: center;
  }

  .tick-icon-amber,
  .statistical-valuation-success-tick {
    font-size: 35px;
    margin-right: 10px;
  }
}

.md-paper--1 {
  box-shadow: none;
}

.statistical-message {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  h6 {
    padding-left: 0px !important;
    display: flex;
    align-items: center;
  }

  .tick-icon-amber,
  .statistical-valuation-success-tick {
    font-size: 35px;
    margin-right: 10px;
  }
}
.property-card-container {
  overflow: auto;

  @include devicepoint(991px) {
    .md-grid {
      width: 50%;
      margin-left: 0;
    }
  }
}

.account-summary-heading {
  display: flex;
  justify-content: space-between;
}

.offset-facility-section {
  @include grid(1fr auto);
  @include grid-gap(40px);

  .facility-icon-list {
    display: flex;
    align-items: center;
    padding-top: 10px;
    img {
      width: 35px;
      height: 38px;
      margin-left: 15px;
    }
  }
  @include devicepoint(tablet) {
    .md-data-table .w-200 {
      width: auto;
    }
  }
}

// review-back-button
.review-back-button {
  margin: 20px 0;
  margin-right: 10px;
}

.review-active {
  transform: translate(0, 0) !important;
}

.comparison-question-mark {
  height: 15px;
  width: 15px;
  line-height: 15px;
  margin-left: 5px;
  fill: #083f67;
  cursor: pointer;
}
.list-table-layout {
  .car-icon,
  .bed-icon,
  .bathroom-icon {
    margin-right: 11px;
  }
  .review-dollar-icon {
    margin-right: 11px;
  }
  .review-house-icon {
    margin-right: 8px;
  }
}

.funds-to-complete-loader {
  text-align: center;
}

.margin-top-5 {
  margin-top: 5px !important;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}
.review-promo {
  height: 20px;
  background-color: $primary-color;
  color: $white;
  border-radius: 3px;
  display: inline-block;
  width: 38%;
  line-height: 20px;
  margin-right: 8px;

  @media only screen and (max-width: 940px) {
    font-size: 10px;
  }
}

.review-legacy-id {
  margin-left: 20px;
}

.review-legacy-id > div > div > .MuiOutlinedInput-input {
  padding: 9.5px 14px !important;
}

.review-legacy-id > div > .MuiInputLabel-outlined {
  margin-top: -9px !important;
  font-size: 15px;
}

.review-legacy-id > div > .MuiInputLabel-outlined.MuiInputLabel-shrink {
  margin-top: 0px !important;
}
