.header {
  display: flex;
  align-items: center;
  padding: 15px 0;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  > img {
    max-width: 80px;
  }
  > .app-logo {
    max-width: 55px;
    height: 48px;
  }
  .header-menu {
    width: auto;
    height: auto;
    margin-right: 30px;
    background: transparent !important;
    padding: 0;
    overflow: visible;
    border-radius: 0 !important;
    .md-icon {
      span {
        margin: 7px 0;
        width: 26px;
        height: 2px;
        display: block;
        background: $gray;
        transform: scale(1.01);
      }
    }
    .md-ink-container {
      display: none;
    }
  }
  .md-menu-container .md-list--menu {
    margin-bottom: 0;
    top: 65px;
    left: 0;
    background: $gray;
    color: $white;
    border-radius: 5px;
    text-transform: uppercase;
    font-weight: 500;
    min-width: 150px;
    .md-text {
      color: $white;
    }
  }
}
.chip-position {
  background-color: var(--primary-color) !important;
  margin: 10px;
}
.text_color {
  color: white;
}
.header--content {
  padding-left: 20px;
  width: calc(100% - 100px);
  display: flex;
  justify-content: space-between;
  h2 {
    margin: 0;
    text-transform: uppercase;
    font-size: $h2 - 2px;
    letter-spacing: 0.01em;
    @include devicepoint(tablet) {
      font-size: $h3;
    }
  }
}
.header--content-2 {
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  h2 {
    margin: 0;
    text-transform: uppercase;
    font-size: $h2 - 2px;
    letter-spacing: 0.01em;
    @include devicepoint(tablet) {
      font-size: $h3;
    }
  }
}

.header-with-promo {
  display: flex;
  flex-direction: column;
}
.page-title {
  text-transform: uppercase;
  color: $green;
  padding: 60px 0 45px 0;
  margin: 0;
}

.app-logo {
  width: 100px;
  height: 100px;
  display: inline-block;
  background-image: url("../images/amm-logo.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.overlay {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
}
// side menu

.side-menu ::-webkit-scrollbar-thumb {
  background: $md-secondary-color;
}

.side-menu ::-webkit-scrollbar {
  width: 4px;
}

aside.side-menu {
  position: fixed;
  left: 0%;
  top: 0;
  bottom: 0;
  background: $white;
  min-width: 420px;
  width: 25%;
  padding: 30px;
  box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  overflow: auto;
  transition: all 600ms ease;
  z-index: 100;
  transform: translateX(-100%);
  @include devicepoint(tablet) {
    min-width: 300px;
  }
  .menu {
    overflow: auto;
    margin: -30px -30px 0;
    padding: 30px 30px 0;
  }
  &-open {
    transform: translateX(0);
  }

  header {
    border-bottom: 1px solid $primary-color;
    position: relative;
    text-transform: uppercase;
    padding-bottom: 15px;
    h4 {
      color: $green;
      font-weight: $font-light;
      margin-bottom: 3px;
      margin-top: 0;
    }
    p {
      font-size: inherit !important;
    }
    .widget--close-btn {
      position: absolute;
      right: -10px;
      top: -10px;
      padding: 0;
      color: $primary-color;
      .md-icon {
        font-size: $h2 + 4;
        color: transparent !important;
        &:before,
        &:after {
          content: "";
          position: absolute;
          width: 26px;
          height: 2px;
          background: $primary-color;
          transform-origin: center center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
  }
  ul {
    list-style: none;
    li a {
      display: block;
      color: inherit;
      padding: 18px 0;
    }
  }
  > .menu > ul > li {
    padding: 0;
    border-bottom: 1px solid $border-color;
    .active {
      font-weight: $font-semibold;
    }
    a:hover {
      opacity: 1;
      background: $gray50;
    }
    a img {
      margin-right: 20px;
      vertical-align: middle;
      margin-top: -5px;
    }
    a .table-icon,
    .sign-out-icon,
    .leads-icon,
    .person-icon,
    .reports-icon,
    .credit-review-icon,
    .branding-icon,
    .gear-icon {
      margin-right: 20px;
      vertical-align: middle;
      margin-top: -5px;
      fill: $green;
    }
    a .house-icon,
    .leads-icon,
    .brand-icon {
      margin-right: 20px;
      vertical-align: middle;
      margin-top: -5px;
      width: 26px;
      height: 24px;
    }

    .sub-nav {
      a {
        margin: 0;
        padding-left: 46px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  footer {
    padding-top: 15px;
    border-top: 1px solid $black;
    margin-top: 30px;
    font-size: $font-size-sm - 1;
    h4 {
      img {
        width: 35px;
        margin-right: 5px;
        vertical-align: sub;
      }
      @include devicepoint(tablet) {
        font-size: $font-size-lg;
        img {
          width: 24px;
        }
      }
    }
    ul {
      li {
        display: inline-block;
        width: 49%;
        vertical-align: top;
        li:nth-child(odd) {
          padding-right: 10px;
        }
        a {
          padding: 0;
          color: $green;
        }
      }
    }
  }
}

.settings-icon {
  color: $green;
  display: inline;
  margin-right: 20px;
}

.MuiTypography-body1 {
  font-weight: 300 !important;
  font-size: 25px !important;
  max-width: 840px;
  color: $green;
}
.MuiSvgIcon-fontSizeSmall {
  color: $green;
}

.breadcrumb-link {
  color: $green500;
}

@media only screen and (max-width: 886px) {
  .MuiTypography-root {
    max-width: 400px;
    font-size: 20px !important;
  }
}

@media only screen and (max-width: 991px) and (min-width: 887px) {
  .MuiTypography-root {
    max-width: 600px;
    font-size: 23px !important;
  }
}

.md-chip-text {
  color: white !important;
}
