.MuiChip-outlined {
  border: 1px solid $green !important;
}

.MuiAvatar-colorDefault {
  color: #fafafa !important;
  background-color: $green !important;
}

.MuiChip-root .MuiChip-avatar {
  color: #ffffff !important;
}
.MuiChip-deleteIcon {
  color: $green !important;
}

.team-header {
  margin-bottom: 10px;
}

.teams-save-button {
  float: right;
}

.team-loader {
  height: 50vh;
  width: 100%;
}

.team-loader.loader-show::after {
  top: 50% !important;
  right: 50% !important;
}

.disable-member {
  td:not(:last-child) {
    pointer-events: none;
    color: gray;
  }
}

.disable-member-all {
  pointer-events: none;
  td:not(:last-child) {
    color: gray;
  }
  td {
    .dashboard-delete-icon {
      pointer-events: all;
    }
  }
}

.float-right {
  float: right;
}

.all-broker-section {
  flex-grow: 1;
  padding: 15px 0 15px 5px;
}

.all-broker-modal {
  flex-grow: 1;
  padding: 5px 0 15px 10px;
}