@media screen and (min-width: 991px) {
  .history-modal {
    .MuiDialog-paperWidthSm {
      min-width: 750px !important;
    }
  }

  .review-modal {
    .MuiDialog-paperWidthSm {
      min-width: 750px !important;
    }
  }

  .action_capitalID,
  .action_submitted,
  .action_approved {
    color: $green;
  }
}

// start

.upload-modal {
  .MuiDialog-container {
    .MuiDialog-paperWidthLg {
      width: calc(100% - 60px);
      max-width: 1920px;
      height: calc(100% - 60px);
    }
  }
  .MuiDialogContent-root {
    padding: 35px 59px 59px 59px;
  }
  .MuiDialogTitle-root {
    height: 80px;
    padding-left: 60px;
    padding-top: 21px;
    background-color: $green;
    .MuiTypography-h6 {
      color: #ffffff;
      font-size: 27px !important;
      font-weight: 300;
    }
  }
  .top-container {
    display: flex;
    width: 100%;
    overflow: hidden;
    height: 100%;

    .left-container {
      width: 70%;
      overflow-y: scroll;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .right-container {
      background-color: #efefef;
      width: 30%;
      overflow-y: scroll;
      padding-right: 0px;

      .support-doc-container {
        .title {
          margin-bottom: 35px;
          font-weight: 450;
          font-size: 18px;
        }
        .heading {
          margin-top: 35px;
        }
        .MuiExpansionPanel-root.Mui-expanded {
          margin: 0;
        }
        .MuiIconButton-edgeEnd {
          margin-right: 0;
        }
        ul {
          margin-left: 15px;
        }
      }
    }

    .MuiPaper-elevation1 {
      box-shadow: none;
    }
  }
}

// end

.note-modal {
  .MuiDialog-container {
    .MuiDialog-paperWidthSm {
      width: 700px !important;
      max-width: 700px;
      height: 370;
      max-height: calc(100% - 80px);
    }
  }

  .MuiDialogContent-root {
    overflow-y: hidden;
  }

  #note-body {
    height: 150px !important;
  }

  .md-text-field.md-text-field--multiline.md-full-width.md-text.md-text-field--multiline-mask {
    height: 150px !important;
  }

  .edit-note-textarea {
    height: 150px;
  }

  .md-text-field-message-container.md-text-field-message-container--count-only.md-text--disabled {
    margin-top: -15px;
    margin-right: 5px;
  }

  #note-modal {
    overflow: scroll;
  }
}

.user-modal-title {
  background-color: $green;
  .MuiTypography-h6 {
    color: #ffffff;
    font-size: x-large !important;
    font-weight: 400;
  }
}

.employment-type-label {
  display: inline-block;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1024px) {
  .employment-type-label {
    margin-bottom: 2px;
  }
}

.modal-sm {
  .MuiDialog-paperWidthSm {
    min-width: 400px;
    min-height: 115px;
  }
}

.modal-md {
  .MuiDialog-paperWidthSm {
    min-width: 450px;
    min-height: 115px;
  }
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 0 10px 10px 10px !important;
  max-height: 35px !important;
}

.history-modal ::-webkit-scrollbar-thumb,
.review-modal ::-webkit-scrollbar-thumb {
  background: $md-secondary-color;
}

.history-modal ::-webkit-scrollbar,
.review-modal ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.note-modal ::-webkit-scrollbar-thumb {
  background: $md-secondary-color;
}

.note-modal ::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.MuiDialogActions-root {
  padding: 8px 24px !important;
  .date-picker {
    width: 60%;
    margin-bottom: 30px;
    margin-right: 85px;
  }
}
@media screen and (max-width: 991px) {
  .MuiDialogActions-root .date-picker {
    margin-bottom: 0px;
    margin-right: 80px;
    margin-top: 30px;
  }
}

#server-upload-file-field {
  width: 95%;
}

.file-inputs__upload-form__file-field {
  .md-text-field-divider-container {
    width: 100%;
  }
}

.broker-notes-modal {
  .MuiDialog-paperWidthSm {
    width: 100%;
  };
  .RichTextEditor__root___2QXK- {
    border: none;
    font-family: "proxima_nova";
    min-height: 300px;
  }
  .broker-notes-modal-text-area-disabled {
    .RichTextEditor__editor___1QqIU {
      cursor: not-allowed !important;
    }
  }
  .DraftEditor-editorContainer {
    height: 260px;
  }
}