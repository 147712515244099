@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-120%, 0, 0);
    transform: translate3d(-120%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-120%, 0, 0);
    transform: translate3d(-120%, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    transform: translate3d(120%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(120%, 0, 0);
    transform: translate3d(120%, 0, 0);
  }
}

.page-transition {
  position: relative;
  margin: 0 -50px;
  height: calc(100vh - 164px);
  width: auto;
  @media only screen and (min-width: 1101px) and (max-width: 1300px) {
    margin: 0 -20px;
    > div {
      padding: 0 20px !important;
    }
  }
  @include devicepoint(1100px) {
    margin: 0 -30px;
  }
  @include devicepoint(991px) {
    margin: 0 -20px;
    height: calc(100vh - 182px) !important;
  }
  @media only screen and (min-width: 1500px) {
    margin: 0 -60px;
    > div {
      padding: 0 60px !important;
    }
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0 50px;
    height: 100%;
    width: 100%;
    overflow: auto;
    overscroll-behavior: auto;
    scroll-behavior: smooth;
    @include devicepoint(1100px) {
      padding: 0 30px;
    }
    @include devicepoint(991px) {
      padding: 0 20px;
    }
    &::-webkit-scrollbar-thumb {
      background: $md-secondary-color;
      visibility: hidden;
    }

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
    &:hover {
      &::-webkit-scrollbar-thumb {
        visibility: visible;
      }
    }
  }
  .slide-prev-enter {
    animation: slideInLeft 0.5s forwards;
  }
  .slide-prev-exit {
    animation: slideOutRight 0.5s forwards;
  }
  .slide-next-enter {
    animation: slideInRight 0.5s forwards;
  }
  .slide-next-exit {
    animation: slideOutLeft 0.5s forwards;
  }
}
