.md-btn--text {
  border-radius: 2px;
  font-weight: 500;
  min-width: 88px;
  padding: 8px 16px;
}
.md-btn:not(:active) .md-ink-container .md-ink {
  opacity: 0 !important;
}

.md-btn:not(.md-btn--icon):not(.md-calendar-date--btn):not(.md-picker-control) {
  min-width: 70px;
  height: auto;
  text-transform: uppercase;
  font-size: $base-font-size;
  font-weight: $font-bold;
  border-radius: 3px;
  &.btn-danger {
    background: $red !important;
  }
  &.btn-primary {
    background: $blue !important;
  }
  &:not([class*="md-background"]):not(.md-btn--dialog) {
    border: 1px solid $primary-color;
    color: $primary-color;
    font-weight: $font-normal;
    background: transparent !important;
    &:hover {
      color: $green500;
      border-color: $green500;
    }
  }
  &.loader-show {
    cursor: no-drop;
    border-color: $gray500 !important;
    &:not(.md-background--secondary) {
      color: $gray500 !important;
    }
    &.md-background--secondary:after {
      background-image: url("../images/white-spinner.svg");
    }

    &:after {
      content: "" !important;
      display: inline-block !important;
      vertical-align: middle;
      visibility: visible;
      margin-right: -11px;
      margin-left: 3px;
      position: static;
      width: 17px;
      height: 17px;
    }
  }
  &.submit-scenario-button {
    text-transform: none;
    font-weight: normal;
    height: none !important;
    margin-top: 25px;
    vertical-align: middle;
    font-weight: auto;
    min-width: 100px;
    font-weight: 700;
  }
  &.document-upload-button {
    @extend .submit-scenario-button;
    width: 180px;
  }
  &.table-submit-btn {
    text-decoration: none;
    height: 28px;
    font-size: 12px;
    min-width: 82px;
  }
}
.md-btn + .loader-show {
  &.approve-loader {
    display: inline-block;
    vertical-align: bottom;
    height: 35px;
  }
  margin-left: 15px;
}

.browse-files {
  display: flex;
  margin-top: 4px !important;
  margin-bottom: 35px;
}

.browse-files-button {
  text-transform: none;
  font-weight: normal;
  vertical-align: middle;
  font-weight: auto;
  min-width: 100px;
  font-weight: 700;
  .md-btn:not(.md-btn--icon):not(.md-calendar-date--btn):not(.md-picker-control) {
    height: 35px;
  }
}

.browse-info-p {
  margin-bottom: 1px !important;
  line-height: 17px !important;
}

.browse-info {
  margin-left: 20px;
}

@media screen and (min-width: 320px) {
  .browse-files-button {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}

.upload-content {
  position: relative;
}

.drag-enter-change {
  opacity: 0.3;
}

.drag-enter-box {
  border-width: 2px;
  border-style: dashed;
  border-color: $green500;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.801);
  justify-content: center;
  align-items: center;
  color: $green500;
  font-size: 36px;
}

.drag-enter-text {
  opacity: 0.99 !important;
  z-index: 3;
  position: relative;
  margin-bottom: -20px;
}

.tag-required-column {
  cursor: pointer;
  color: #ff0000 !important;
}

.tag-required-column-disabled {
  cursor: no-drop;
  color: #ff0000 !important;
}

.tag-column {
  cursor: pointer;
}

.tag-column-disabled {
  cursor: no-drop;
}

.tags {
  min-width: 195px;
  width: 195px;

  @media only screen and (min-width: 1500px) {
    min-width: auto;
    width: auto;
  }

  white-space: break-spaces;
}

.md-btn.md-background--secondary-hover:not([disabled]):hover {
  background: $green500 !important;
}
.md-btn[disabled] {
  cursor: no-drop;
  &.md-background--secondary {
    background: $gray500 !important;
  }
  &:not([class*="md-background"]) {
    border-color: $gray500 !important;
    color: $gray500 !important;
  }
}

.plusIcon {
  padding: 0;
  width: 20px;
  height: 20px;
  background: transparent !important;
  .md-ink-container {
    display: none;
  }
  .md-icon {
    display: block;
    font-family: inherit;
    font-weight: $font-normal;
    font-style: normal;
    color: $primary-color;
    &:before {
      content: "+";
      font-size: 38px;
      display: block;
      line-height: 0;
      font-family: proxima_nova;
      font-weight: 300;
      transform: scale(1.1, 1);
    }

    span {
      position: absolute;
      right: 0;
      top: -8px;
      padding-right: 27px;
      font-family: $Primary-font;
      font-size: $base-font-size;
      line-height: 35px;
      opacity: 0;
      transition: opacity 150ms ease;
    }
  }

  &:hover .md-icon span {
    opacity: 1;
  }
}

.buttons__group .md-btn {
  margin-right: 20px;
}

.btn-group {
  display: flex;
  align-items: center;

  .md-btn:first-child {
    margin-right: 10px;
  }
}

.add_refresh--btn {
  display: flex;
  justify-content: flex-end;
  .md-btn {
    margin: 0px 0px 8px 5px;
  }
}
.page_next-prev--btn {
  display: flex;
  justify-content: space-between;
  .md-btn:last-child {
    margin-right: 0;
  }
}

.send-quote-margin {
  margin-right: 20px;
}

.task-button {
  background: none;
  border: none !important;
  font-family: "Roboto", sans-serif !important;
  margin-left: 5px !important;
  font-weight: inherit !important;
  text-transform: none !important;
  font-size: 1rem !important;
  padding: 0px !important;

  &:hover {
    color: $green500;
  }
}

.scenario-upload-button{
  pointer-events: none;
  color: #999;
}

.wrapper-upload-button{
  display: inline !important;
}
.wrapper-upload-button-disabled{
  display: inline !important;
  cursor: not-allowed !important;
}

.preliminary-pdf-button {
  background: transparent !important;
  text-transform: uppercase;
  font-size: 15px;
  color: #000;
  font-weight: 400 !important;

  line-height: 1.5 !important;

  .MuiButton-label {
    font-size: 15px !important;
  }
  margin-right: 20px !important;
  padding: 6px 15px;
}
.preliminary-pdf-loader:after {
  right: 10px !important;
  bottom: 0px !important;
}

button#preliminary-pdf {
  border: 1px solid;
}

.update-status-button {
  font-size: 15px !important;
  background: var(--primary-color) !important;
  color: #fff !important;
  padding: 6px 15px !important;
  line-height: 1.5 !important;
  font-weight: 600 !important;
  border-radius: 3px !important;
  &:hover {
    background: var(--secondary-color) !important;
  }
}

.margin-5px {
  margin: 0px 5px;
  &:after {
    bottom: 0 !important;
  }
}

.md-btn:not(.md-btn--icon):not(.md-calendar-date--btn):not(.md-picker-control).table-submit-btn {
  text-decoration: none;
  height: 20px; /* shorter button height */
  font-size: 12px;
  min-width: 55px; /* narrower button width */
  line-height: 12px; /* adjusted to match font-size */
  top: -5px; /* center button vertical positioning (on desktop) */
  margin-right: 8px; /* add space between button and other actions */
}

.md-btn:not(.md-btn--icon):not(.md-calendar-date--btn):not(.md-picker-control).table-docs-btn {
  text-decoration: none;
  height: 20px; /* shorter button height */
  font-size: 12px;
  min-width: 55px; /* narrower button width */
  line-height: 12px; /* adjusted to match font-size */
  top: -5px; /* center button vertical positioning (on desktop) */
  margin-right: 8px; /* add space between button and other actions */
  padding: 3px 5px 3px 5px;
}

.table-submit-btn.disabled {
  border: 1px solid #ccc !important;
  padding: 0px !important;
}

.new-scenario-button {
  margin-right: 20px;
}

#review-modal-add-new-button {
  float: right;
}

.review-modal-close-button {
  padding: 0;
  width: 20px;
  height: 20px;
}

.file-upload-remove-button {
  padding: 0;
  width: 20px;
  height: 20px;
  position: relative;
  top: 6px;
  left: -34px;
  &:hover {
    color: var(--primary-color);
  }
}
