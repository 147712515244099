.check-tick-icon-cls-1 {
  fill: $salem;
}

.check-tick-icon-submit-scenario {
  fill: $salem;
  margin-right: 5px;
  margin-left: 2px;
  vertical-align: middle;
}

.review-cross-icon-submit-scenario {
  width: 15px;
  height: 15px;
  margin-right: 5px;
  margin-left: 2px;
  vertical-align: middle;
}

.MuiSvgIcon-root {
  &.exclamation-icon-submit-scenario {
    color: $amber;
    margin-right: 3px;
    margin-left: 0px !important;
    font-size: 20px;
    vertical-align: middle;
  }
}
.check-tick-icon-cls-2 {
  fill: #fff;
}

.copy-icon-wrap {
  filter: url(#filter);
}

.reviewCrossIcon-cls1 {
  fill: $red;
}

.reviewCrossIcon-cls2 {
  fill: #fff;
}

.copy-icon-inner {
  fill: none;
  stroke: $gray100;
  stroke-width: 2px;
}

.review-cross-icon {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  margin-top: 2px;
  margin-bottom: -2px;
}

.review-done-icon {
  background: $green;
  color: $white !important;
  font-weight: 700;
  border-radius: 50%;
  transition: background 150ms ease;
  width: 14px;
  height: 14px !important;
  font-size: 14px !important;
  line-height: 14px;
  display: inline-block;
  position: relative;
  margin-left: 0px !important;
  margin-right: 5px !important;
  top: 2px !important;
}
.dollar {
  margin-bottom: -2px;
}

.car-icon,
.bed-icon,
.calendar-icon,
.person-icon,
.reports-icon,
.credit-review-icon,
.branding-icon,
.dollar-icon,
.bathroom-icon,
.review-house-icon,
.review-dollar-icon,
.house-icon,
.brand-icon,
.leads-icon,
.objective-house-icon .objective-category-icon,
.objective-key-icon,
.objective-bed-icon,
.objective-car-icon,
.objective-bathroom-icon,
.objective-dollar-icon {
  fill: $green;
}
