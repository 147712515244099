.user-detail-grid {
  max-width: calc(100% - 16px);
  &.user-list-wrap {
    max-width: 500px;
  }
}

#select-field-default-value-menu-options {
  background: $white;
}

#select-field-default-value-menu .md-icon-separator {
  border-bottom: 1px solid $lightgray;
}

.user-detail-proviso-tabs {
  padding-top: 40px;

  ul {
    background: $lightgray;
  }

  .md-tab-indicator {
    background: $darkgray;
  }

  .md-tab-label {
    color: $gray;
  }
}

.user-save-button,
.user-reset-button,
.user-delete-button {
  border-radius: 4px;
  margin: 20px;
}

.user-reset-button,
.user-delete-button {
  border: 1px solid $darkgray;
}
.linked-services {
  &.gray-bg {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  margin-bottom: 70px;
}
