.md-picker .md-picker-content-container .md-dialog-footer {
  .md-text--theme-primary {
    color: inherit;
  }
  .md-btn:not(.md-text--theme-secondary) {
    border: 1px solid $primary-color;
    color: $primary-color;
    font-weight: $font-normal;
    background: transparent !important;
    &:hover {
      color: $green500;
      border-color: $green500;
    }
  }
  .md-text--theme-secondary {
    background: $green;
    color: $white !important;
    border-color: $green;
    &:hover {
      background: $green500;
      border-color: $green500;
    }
  }
}

.md-picker--portrait {
  display: block;
  width: auto;
  .md-picker-header {
    width: 100%;
    background: $green;
  }
  .md-calendar-date--btn-active:after {
    background: $green;
  }
}

.md-overlay {
  background: rgba(0, 0, 0, 0.75);
  bottom: 0;
  left: 0;
  opacity: 1;
  position: fixed;
  right: 0;
  top: 0;
  transition-duration: 0.15s;
  transition-property: opacity;
  z-index: 16;
}

.md-dialog--centered {
  left: calc(50% - 185px);
  top: calc(50% - 250px);
  transform: none !important;
}

.md-calendar-date--date {
  position: relative;
  z-index: 1;
  font-size: 15px;
}

.md-dialog-footer--inline {
  padding: 8px 15px 20px 30px;
}

.md-dialog-footer--inline .md-btn--dialog + .md-btn--dialog {
  margin-left: 8px;
  background: $green;
  color: $white;
  font-weight: 500 !important;
}

.md-dialog-footer--inline .md-btn--dialog + .md-btn--dialog {
  background: $green500;
}

// material Ui datepicker
.date-picker {
  .MuiInputBase-root {
    font: inherit;
    background: transparent !important;
    > label {
      line-height: 1.6;
      text-rendering: auto;
      letter-spacing: 0.02em;
    }
    input {
      padding: 35px 12px 10px;
      padding-left: 0px;
      margin-bottom: -3px;
    }
  }
  .MuiInputAdornment-filled.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
    margin-top: 26px;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    transform: none;
    color: inherit;
    font-size: 15px;
    font-family: inherit;
    top: 4px;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink.md-text--error {
    color: $red !important;
  }
  .MuiFormHelperText-contained {
    margin-left: 0;
    color: $red !important;
    font-size: 12px;
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    .MuiInputBase-root {
      margin-bottom: 60px;
    }
    .MuiFormLabel-root,
    .MuiInputLabel-filled.MuiInputLabel-shrink {
      font-size: 14px;
    }
  }
}
.MuiPickersToolbar-toolbar {
  background: $green !important;
}
.MuiPickersDay-daySelected {
  background: $green !important;
}
.MuiButton-textPrimary {
  color: $green !important;
}
button.MuiButtonBase-root.MuiIconButton-root.date-btn {
  left: -20px;
}
.MuiInputAdornment-positionStart {
  margin-right: -18px !important;
}
.MuiPickersCalendarHeader-transitionContainer {
  height: 35px !important;
}

.margin-left-100 {
  margin-left: 100px !important;
}
.margin-left-106 {
  margin-left: 106px !important;
}
.margin-left-20 {
  margin-left: 20px !important;
}
.MuiDialogActions-spacing .date-picker .MuiInputBase-root {
  margin-bottom: 0px !important;
}
.MuiDialogActions-spacing .date-picker {
  margin-bottom: 30px !important;
}
